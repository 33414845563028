const Interno = () => import('@/shared/components/layout/Interno')
const ListaGrupos = () => import('@/domains/shared/configuracoes/grupos_usuarios/views/Listar')
const AdicionarGrupo = () => import('@/domains/shared/configuracoes/grupos_usuarios/views/Adicionar')
const EditarGrupo = () => import('@/domains/shared/configuracoes/grupos_usuarios/views/Editar')
const VisualizarGrupo = () => import('@/domains/shared/configuracoes/grupos_usuarios/views/Visualizar')

const routes = [
    {
        path: '/',
        component: Interno,
        children: [
            {
                name: 'listarGruposUsuarios',
                path: '/grupos_usuarios',
                alias: ['/grupos_usuarios/index'],
                component: ListaGrupos,
            },
            {
                name: 'adicionarGrupoUsuarios',
                path: '/grupos_usuarios/adicionar',
                component: AdicionarGrupo,
            },
            {
                name: 'editarGrupoUsuarios',
                path: '/grupos_usuarios/editar/:id',
                component: EditarGrupo,
            },
            {
                name: 'visualizarGrupoUsuarios',
                path: '/grupos_usuarios/visualizar/:id',
                component: VisualizarGrupo,
            },
        ]
    },
]

export default routes
