const routes = [
    {
        path: '/atributos_contratos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAtributosContratos',
                path: '/atributos_contratos',
                alias: '/atributos_contratos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarAtributoContrato',
                path: '/atributos_contratos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarAtributoContrato',
                path: '/atributos_contratos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarAtributoContrato',
                path: '/atributos_contratos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
