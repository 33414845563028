const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarTransferencias',
                path: '/transferencias_estoques',
                alias: '/transferencias_estoques/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarTransferencias',
                path: '/transferencias_estoques/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'visualizarTransferencias',
                path: '/transferencias_estoques/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
        ]
    },
    {
        name: 'imprimirTransferencia',
        path: '/transferencias_estoques/imprimir_pdf/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
]

export default routes
