const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'painelOrdensServicos',
                path: '/ordens_servicos_painel',
                alias: ['ordens_servicos_painel/index'],
                component: () => import('../views/Painel')
            }
        ]
    }
]

export default routes
