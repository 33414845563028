import Vue from 'vue'
import $api from '@/shared/services/api'

export default {
    namespaced: true,
    state: {
        empresaPlano: null,
    },
    getters: {
        pegaEmpresaPlano(state){
            return state.empresaPlano
        },
    },
    mutations: {
        atualizaEmpresaPlano(state, empresaPlano){
            state.empresaPlano = empresaPlano
        },
    },
    actions: {
        async carregaEmpresaPlano({ commit }){
            try {
                const retorno = await $api.cGet(
                    Vue.prototype.$urlBase + '/planos/valida_plano_atual'
                )
                if(retorno.status == 'success'){
                    commit('atualizaEmpresaPlano', retorno.data.EmpresasPlano)
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
}
