import Vue from 'vue'
import { somenteNumeros } from './global'
Vue.directive('mascara-telefone', {
    inserted: function (el) {
        const limite = 14
        el.maxLength = limite
        el.addEventListener('keypress', function( e ) {
            return somenteNumeros(e) 
        }, true)
        el.addEventListener('focus', function() {
            el.setAttribute('placeholder', '(__)____-____')
        }, true)
        el.addEventListener('blur', function() {
            el.setAttribute('placeholder', '')
        }, true)
        el.addEventListener('keyup', function(e) {
        
            let vr = e.target.value;
            vr = vr.replace(/[^0-9\\.]+/g, '')
            let tam = vr.length + 1
            
            if (!/backspace/i.test(e.key)) {
                if (tam == 3){
                    e.target.value = '(' + vr.substr(0,3) + ')' + vr.substr(3,tam)
                } else if (tam == 7) {
                    e.target.value = '(' + vr.substr(0, 2) + ')' + vr.substr(2,6) + '-' + vr.substr(6,tam)
                } else if (tam == 11) {
                    e.target.value = '(' + vr.substr(0, 2) + ')' + vr.substr(2,4) + '-' + vr.substr(6,tam)
                } else if (tam == 12) {
                    e.target.value = '(' + vr.substr(0, 2) + ')' + vr.substr(2,5) + '-' + vr.substr(7,tam)
                } else  if(e.target.value.length >= limite){
                    let txt = e.target.value.substring(0, limite)
                    e.target.value = txt
                }
            }
        }, true)
    }
})