const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                path: '/movimentacoes_financeiras/dre_gerencial',
                component: () => import('../views/Relatorio'),
            },
            {
                path: '/categorias_dre',
                component: () => import('../views/Listar')
            },
            {
                path: '/categorias_dre/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                path: '/categorias_dre/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                path: '/categorias_dre/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes