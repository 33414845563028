const Visualizar = () => import('../views/Visualizar')
const Listar = () => import('../views/Listar')
const Editar = () => import('../views/Editar')
const Adicionar = () => import('../views/Adicionar')
const Interno = () => import('@/shared/components/layout/Interno')

const routes = [
    {
        path: '/',
        component: Interno,
        children: [
            {
                name: 'ListarServicos',
                path: '/servicos',
                component: Listar
            },
            {
                name: 'visualizarServico',        
                path: '/servicos/visualizar/:id',
                component: Visualizar
            },
            {
                name: 'adicionarServico', 
                path: '/servicos/adicionar',
                component: Adicionar
            },
            {
                name: 'editarServico',      
                path: '/servicos/editar/:id',
                component: Editar
            },
        ]
    }
]

export default routes
