const MarcaEmpresa = () => import('../views/Editar')
const Interno = () => import('@/shared/components/layout/Interno')

const routes = [
    {
        path: '/configuracoes/marca_empresa',
        component: Interno,
        children: [
            {
                name: 'MarcaEmpresa',
                path: '/',
                component: MarcaEmpresa
            }
        ]
    }
]

export default routes
