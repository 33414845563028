import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies)

const TOKEN_ID = 'x-token-auth'

export const pegaToken = () => {
    return Vue.$cookies.get(TOKEN_ID)
};

export const salvaToken = (token) => {
  //Vue.$cookies.config('7d', '/')
  Vue.$cookies.config('365d', '', '', false)
  Vue.$cookies.set(TOKEN_ID, token)
}

export const atualizaToken = () => {
    window.onfocus = () => {
        Vue.$cookies.get(TOKEN_ID)
    }
}

export default { pegaToken }