const routes = [
    {
        path: '/atributos_clientes/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAtributosClientes',
                path: '/atributos_clientes',
                alias: '/atributos_clientes/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarAtributoCliente',
                path: '/atributos_clientes/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarAtributoCliente',
                path: '/atributos_clientes/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarAtributoCliente',
                path: '/atributos_clientes/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
