const routes = [
  {
    path: '/',
    component: () => import('@/shared/components/layout/Interno'),
    children: [
      {
        path: '/relatorios_cadastros',
        component: () => import('../views/Listar')
      },
      {
          name: 'formularioRelatoriosCadastro',
          path: '/relatorios_cadastros/relatorio_clientes',
          component: () => import('../views/Cliente')
      },
      {
          name: 'formularioRelatoriosAniversariante',
          path: '/relatorios_cadastros/relatorio_aniversariantes',
          component: () => import('../views/Aniversariante')
      },
      {
          name: 'formularioRelatoriosFornecedor',
          path: '/relatorios_cadastros/relatorio_fornecedores',
          component: () => import('../views/Fornecedor')
      },
      {
          name: 'formularioRelatoriosFuncionario',
          path: '/relatorios_cadastros/relatorio_funcionarios',
          component: () => import('../views/Funcionario')
      },
      {
          name: 'formularioRelatoriosTransportadora',
          path: '/relatorios_cadastros/relatorio_transportadoras',
          component: () => import('../views/Transportadora')
      },
      {
          name: 'formularioRelatoriosProduto',
          path: '/relatorios_cadastros/relatorio_produtos',
          component: () => import('../views/Produto')
      },
      {
          name: 'formularioRelatoriosServico',
          path: '/relatorios_cadastros/relatorio_servicos',
          component: () => import('../views/Servico')
      },
    ]
  },
  {
      name: 'impressaoRelatorio',
      path: '/relatorios_cadastros/:tipo/gerar',
      component: () => import('@/shared/components/ui/Relatorio')
  },
]
export default routes
