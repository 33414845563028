const Interno = () => import('@/shared/components/layout/Interno')
const ListarAtendimentos = () => import('@/domains/atendimentos/atendimentos/views/Listar')
const AdicionarAtendimento = () => import('@/domains/atendimentos/atendimentos/views/Adicionar')
const EditarAtendimento = () => import('@/domains/atendimentos/atendimentos/views/Editar')
const VisualizarAtendimento = () => import('@/domains/atendimentos/atendimentos/views/Visualizar')
const Impressao = () => import('@/shared/components/ui/Impressao')

const routes = [
    {
        path: '/atendimentos/:id?',
        component: Interno,
        children: [
            {
                name: 'listarAtendimentos',
                path: '/atendimentos',
                alias: '/atendimentos/index',
                component: ListarAtendimentos,
            },
            {
                name: 'adicionarAtendimentos',
                path: '/atendimentos/adicionar',
                component: AdicionarAtendimento,
            },
            {
                name: 'editarAtendimentos',
                path: '/atendimentos/editar/:id',
                component: EditarAtendimento,
            },
            {
                name: 'visualizarAtendimentos',
                path: '/atendimentos/visualizar/:id',
                component: VisualizarAtendimento,
            },            
        ]
    },
    {
        name: 'imprimirAtendimento',
        path: '/atendimentos/imprimir_pdf/:id',
        component: Impressao,
        meta: {
            fullScreen: true,
            public: true
        },
    },
]

export default routes
