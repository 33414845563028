const routes = [
    {
        path: '/tipos_contatos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarTiposContatos',
                path: '/tipos_contatos',
                alias: '/tipos_contatos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarTipoContato',
                path: '/tipos_contatos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarTipoContato',
                path: '/tipos_contatos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarTipoContato',
                path: '/tipos_contatos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
