<template>
    <b-input-group ref="inputSenha" class="password-input-group">
      <div class="input-wrapper">
        <slot>
          <input
            :id="dId"
            :name="dName"
            type="password"
            :disabled="disabled"
            :value="value"
            placeholder="Digite sua senha"
            class="form-control custom-input"
            v-validate="(required ? 'required|' : '') +'senha'"
            :class="[{ error: errors.has('senha') }]"
          />
        </slot>
        <b-button
          v-if="toggleShowPassword"
          id="button"
          variant="link"
          :disabled="disabled"
          @click="showPassword"
          class="btn p-0"
        >
          <i
            class="exibir-senha"
            :class="dShowPassword ? 'fa fa-eye text-primary mb-1' : 'fa fa-eye-slash text-primary mb-1'"
          
          ></i>
        </b-button>
      </div>
    </b-input-group>
  </template>
  
  <style scoped>
    .password-input-group {
        position: relative;
    }
    
    .password-input-group .input-wrapper {
        position: relative;
        display: flex;
        width: 100%;
    }
    .password-input-group .input-wrapper .custom-input {
        width: 100%;
        box-sizing: border-box;
    }

    .password-input-group .input-wrapper button {
        margin-left: -30px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    .text-primary {
        color: #1c1d1d;
    }
  </style>
  

<script>
import { validaTamanhoSenha, validaLetrasSenha, validaNumerosSenha, validaCaractereEspecialSenha } from '@/shared/common/functions/validacoes'
export default {
    inject: ['$validator'],
    props: {
        id: {
            default: ''
        },
        name: {
            default: ''
        },
        value: {
            default: ''
        },
        disabled: {
            default: false
        },
        required: {
            default: false
        },
        toggleShowPassword:{
            default: true
        },
    },
    data() {
        return {
            dId: this.id || this.name, 
            dName: this.name || this.id,
            dValue: this.value,
            input: null,
            dShowPassword: false,
            lengthValid: false,
            upperCaseValid: false,
            numberValid: false,
            specialCharValid: false,
        }
    },
    mounted() {
        this.inputSenha = this.$refs.inputSenha.querySelector('input')
        this.inputSenha.addEventListener('input', (e) => {
            this.validatePassword()
            this.$emit('input', e.target.value)
        })
    },
    methods: {
        showPassword() {
            if (!this.dShowPassword) {
                this.inputSenha.type = 'text'
            } else {
                this.inputSenha.type = 'password'
            }
            this.dShowPassword = !this.dShowPassword
        },
        validatePassword() {
            this.lengthValid = validaTamanhoSenha(this.inputSenha.value)
            this.upperCaseValid = validaLetrasSenha(this.inputSenha.value)
            this.numberValid = validaNumerosSenha(this.inputSenha.value)
            this.specialCharValid = validaCaractereEspecialSenha(this.inputSenha.value)
        }
    }
}
</script>
