const routes = [
    {
        path: '/cheques/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarCheques',
                path: '/cheques',
                alias: '/cheques/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarCheque',
                path: '/cheques/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarCheque',
                path: '/cheques/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarCheque',
                path: '/cheques/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
