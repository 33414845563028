import Vue from 'vue'
import { somenteNumeros, somenteNumerosVirgula , somenteNumerosVirgulaPonto} from './global'

Vue.directive('mascara-numero', {
    inserted: function (el, binding) {
        el.addEventListener('keypress', function( e ) {
            return somenteNumeros(e) 
        }, true)
        el.style.textAlign = binding.value || 'right'
    }
})
Vue.directive('mascara-numero-virgula', {
    inserted: function (el, binding) {
        el.addEventListener('keypress', function( e ) {
            return somenteNumerosVirgula(e) 
        }, true)
        el.style.textAlign = binding.value || 'left'
    }
})
Vue.directive('mascara-numero-virgula-ponto', {
    inserted: function (el, binding) {
        el.addEventListener('keypress', function( e ) {
            return somenteNumerosVirgulaPonto(e)
        }, true)
        el.style.textAlign = binding.value || 'left'
    }
})

Vue.directive('mascara-numero-3-digitos', {
    inserted: function (el) {
        const limite = 3
        el.maxLength = limite
        el.addEventListener('keypress', function( e ) {
            return somenteNumeros(e) 
        }, true)
        el.addEventListener('focus', function() {
            el.setAttribute('placeholder', '____')
        }, true)
        el.addEventListener('blur', function() {
            el.setAttribute('placeholder', '')
        }, true)
        el.addEventListener('keyup', function(e) {
            if(e.target.value.length >= limite){
                let txt = e.target.value.substring(0, limite)
                e.target.value = txt
            }
        }, true)
    }
})