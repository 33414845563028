const routes = [
    {
        path: '/categorias_movimentacoes_financeiras/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarPlanoDeContas',
                path: '/categorias_movimentacoes_financeiras',
                alias: '/categorias_movimentacoes_financeiras/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarPlanoDeConta',
                path: '/categorias_movimentacoes_financeiras/adicionar',
                component: () => import('@/domains/shared/financeiro/auxiliares/plano_contas/views/Adicionar')
            },
            {
                name: 'editarPlanoDeConta',
                path: '/categorias_movimentacoes_financeiras/editar/:id',
                component: () => import('@/domains/shared/financeiro/auxiliares/plano_contas/views/Editar')
            },
            {
                name: 'visualizarPlanoDeConta',
                path: '/categorias_movimentacoes_financeiras/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
