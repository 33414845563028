const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarDevolucoes',
                path: '/devolucoes',
                alias: '/devolucoes/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarDevolucao',
                path: '/devolucoes/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'visualizarDevolucao',
                path: '/devolucoes/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    },
    {
        name: 'impressaoDevolucao',
        path: '/devolucoes/imprimir_pdf/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        }
    }
]
export default routes
