import Vue from 'vue'

export const formataDataHora = (data) => {
    if (Vue.prototype.$moment(data, 'DD/MM/YYYY HH:mm:ss', true).isValid()) {
        return data
    } else {
        return Vue.prototype.$moment(data).format('DD/MM/YYYY HH:mm:ss')
    }
}

export const formataData = (data) => {
    if (validaData(data)) {
        return data
    } else {
        return Vue.prototype.$moment(data).format('DD/MM/YYYY')
    }
}

export const validaData = (data, formato = 'DD/MM/YYYY') => {
    if (data.length != 10) {
        return false
    }
    return Vue.prototype.$moment(data, formato).isValid()
}

export const formataDataAmericana = (data) => {
    return Vue.prototype.$moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD')
}

export const dataAtual = (formato = 'DD/MM/YYYY', dias = 0) => {
    return Vue.prototype.$moment().add(dias, 'days').format(formato)
}

export const formataHora = (hora) => {
    if (hora) {
        const tam = hora.length
        if (tam > 5) {
            hora = hora.replace(/\D/g, '')
            hora = hora.substr(0, 2) + ':' + hora.substr(2, 2)
        }
        return hora
    }
    return hora
}

export const listaMeses = (mes = null) => {
    const meses = {
        '01': 'jan',
        '02': 'fev',
        '03': 'mar',
        '04': 'abr',
        '05': 'mai',
        '06': 'jun',
        '07': 'jul',
        '08': 'ago',
        '09': 'set',
        10: 'out',
        11: 'nov',
        12: 'dez'
    }
    if (mes) {
        return meses[mes]
    }
    return meses
}

export const listaNomeMeses = (mes = null) => {
    const meses = {
        '01': 'Janeiro',
        '02': 'Fevereiro',
        '03': 'Março',
        '04': 'Abril',
        '05': 'Maio',
        '06': 'Junho',
        '07': 'Julho',
        '08': 'Agosto',
        '09': 'Setembro',
        10: 'Outubro',
        11: 'Novembro',
        12: 'Dezembro'
    }
    if (mes) {
        return meses[mes]
    }
    return meses
}

export const validaMinDataHoje = (data) => {
    const dataHoje = Vue.prototype.$moment().format('YYYY-MM-DD')
    const _data = Vue.prototype.$moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD')

    if (Vue.prototype.$moment(_data).isBefore(dataHoje)) {
        return false
    } else {
        return true
    }
}

export const validaAno = (data) => {
    const anoMinimo = '1800'
    const _anoData = Vue.prototype.$moment(data, 'DD/MM/YYYY').format('YYYY')

    if (Vue.prototype.$moment(_anoData).isBefore(anoMinimo)) {
        return false
    } else {
        return true
    }
}
