import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { formataUrlBase, validaSite } from '@/shared/common/functions/roteamento'
import { validaAutenticacao } from '@/shared/common/functions/autenticacao'
import { insereNovidades } from '@/shared/plugins/novidades'
import loading from '@/shared/plugins/loading'

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({
            behavior: "smooth"
        });
    }
})

router.beforeEach(async (to, from, next) => {

    insereNovidades(to.path)
    formataUrlBase(to.fullPath)
    
    if (to.path === '/') {
        loading.show()
        const possuiSite = await validaSite()
        if (possuiSite) {
            return window.location.href = '/site'
        } else {
            const isAutenticado = await validaAutenticacao()
            if (!isAutenticado) {
                return window.location.href = '/login'
            }else{
                return window.location.href = '/inicio'
            }
        }
    }

    if (!to.meta.public) {
        loading.show()
        const isAutenticado = await validaAutenticacao()
        if (!isAutenticado) {
            return window.location.href = Vue.prototype.$urlBase + '/login'
        }
        loading.hide()
    }

    next()
})

export default router
