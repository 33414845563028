<template>
    <div>
        <barra-titulo 
            titulo="Adicionar contrato" 
            :itens="breadcrumb" 
        />
        <formulario />
    </div>
</template>
<script>
export default {
    components: {
        Formulario: () => import('../components/Formulario')
    },
    data() {
        return {
            breadcrumb: [
                {
                    descricao: 'Contratos de serviços',
                    url: '/contratos_servicos'
                },
                {
                    descricao: 'Adicionar'
                }
            ]
        }
    }
}
</script>
<style>
    
</style>