import { Validator } from 'vee-validate'
import { validaSenha } from '@/shared/common/functions/validacoes'

Validator.localize('pt')

Validator.extend('senha', {
    getMessage: 'Digite uma senha válida',
    validate: ((senha) => {
        return validaSenha(senha)
    })
});

