const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarVendasBalcao',
                path: '/vendas_balcao',
                alias: ['/vendas_balcao/index', '/pdv', '/pdv/index'],
                component: () => import('../views/Listar')
            },
            {
                name: 'venderVendaBalcao',
                path: '/vendas_balcao/vender',
                alias: ['/vendas_balcao/adicionar'],
                component: () => import('../views/Vender'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'pagarVendaBalcao',
                path: '/vendas_balcao/pagar',
                component: () => import('../views/Pagar'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'finalizarVendaBalcao',
                path: '/vendas_balcao/finalizar/:id',
                component: () => import('../views/Finalizar'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'abrirCaixaVendaBalcao',
                path: '/vendas_balcao/abrir_caixa',
                component: () => import('../views/Caixa/Abrir'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'fecharCaixaVendaBalcao',
                path: '/vendas_balcao/fechar_caixa',
                component: () => import('../views/Caixa/Fechar'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'visualizarCaixaVendaBalcao',
                path: '/vendas_balcao/visualizar_caixa',
                component: () => import('../views/Caixa/Visualizar'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'realizarSangriaVendaBalcao',
                path: '/vendas_balcao/realizar_sangria',
                component: () => import('../views/Caixa/RealizarSangria'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'visualizarSangriaVendaBalcao',
                path: '/vendas_balcao/visualizar_sangria/:id',
                component: () => import('../views/Caixa/VisualizarSangria'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'realizarReforcoVendaBalcao',
                path: '/vendas_balcao/realizar_reforco',
                component: () => import('../views/Caixa/RealizarReforco'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'visualizarReforcoVendaBalcao',
                path: '/vendas_balcao/visualizar_reforco/:id',
                component: () => import('../views/Caixa/VisualizarReforco'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'realizarDevolucaoVendaBalcao',
                path: '/vendas_balcao/realizar_devolucao',
                component: () => import('../views/Devolucao'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'visualizarDevolucaoVendaBalcao',
                path: '/vendas_balcao/visualizar_devolucao',
                component: () => import('../views/Devolucao/Visualizar'),
                meta: {
                    fullScreen: true,
                    botaoSairPdv: true
                }
            },
            {
                name: 'editarVendaBalcao',
                path: '/vendas_balcao/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarVendaBalcao',
                path: '/vendas_balcao/visualizar/:id',
                component: () => import('../views/Visualizar'),
                meta: {
                    tipoVisualizacao: 'orcamento'
                }
            },
            {
                name: 'agruparVendaBalcao',
                path: '/vendas_balcao/agrupar_vendas',
                component: () => import('../views/Agrupar'),
                meta: {
                    fullScreen: true
                }
            }
        ]
    },
    {
        name: 'impressaoVendaBalcao',
        path: '/ped/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        }
    },
    {
        name: 'impressaoCupomHashVendaBalcao',
        path: '/cupom/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        }
    },
    {
        name: 'impressaoCupomIdVendaBalcao',
        path: '/vendas_balcao/imprimir_cupom/:id',
        component: () => import('../components/Pdv/ImpressaoCupom'),
        meta: {
            fullScreen: true,
            public: true
        }
    },
    {
        name: 'cupomPresenteHashVendaBalcao',
        path: '/balcao_cupom_presente/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        }
    },
    {
        name: 'cupomPresenteIdVendaBalcao',
        path: '/vendas_balcao/imprimir_cupom_presente/:id',
        component: () => import('../components/Pdv/ImpressaoCupom'),
        meta: {
            fullScreen: true,
            public: true
        }
    }
]

export default routes
