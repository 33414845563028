const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarSituacoesDeSituacoesMovimentacoesFinanceiras',
                path: '/situacoes_movimentacoes_financeiras',
                alias: '/situacoes_movimentacoes_financeiras',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarSituacoesDeSituacoesMovimentacoesFinanceiras',
                path: '/situacoes_movimentacoes_financeiras/adicionar',
                component: () => import('../views/Adicionar'),
            },
            {
                name: 'visualizarSituacoesDeSituacoesMovimentacoesFinanceiras',
                path: '/situacoes_movimentacoes_financeiras/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            {
                name: 'editarSituacoesDeSituacoesMovimentacoesFinanceiras',
                path: '/situacoes_movimentacoes_financeiras/editar/:id',
                component: () => import('../views/Editar'),
            },
        ]
    },
]
export default routes