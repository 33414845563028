const routes = [
    {
        path: '/formas_pagamentos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarFormasDePagamentos',
                path: '/formas_pagamentos',
                alias: '/formas_pagamentos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarFormaDePagamento',
                path: '/formas_pagamentos/adicionar',
                component: () => import('@/domains/shared/financeiro/auxiliares/formas_pagamentos/views/Adicionar')
            },
            {
                name: 'editarFormaDePagamento',
                path: '/formas_pagamentos/editar/:id',
                component: () => import('@/domains/shared/financeiro/auxiliares/formas_pagamentos/views/Editar')
            },
            {
                name: 'visualizarFormaDePagamento',
                path: '/formas_pagamentos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
