export const validaEmail = email => {
    return (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
}

export const validaTamanhoSenha = ((senha) => {
    if (senha.length >= 8){
        return true;
    } else {
        return false;
    }
})
export const validaLetrasSenha = ((senha) => {
    var upperCaseValid = /[A-Z]/.test(senha) && /[a-z]/.test(senha);
    if (upperCaseValid){
        return true;
    } else {
        return false;
    }
})
export const validaNumerosSenha = ((senha) => {
    var numberValid = /\d/.test(senha);
    if (numberValid){
        return true;
    } else {
        return false;
    }
})
export const validaCaractereEspecialSenha = ((senha) => {
    var specialCharValid = /[!@#$%^&*()_+\-=[\]{};':"|,.<>?]+/.test(senha);
    if (specialCharValid){
        return true;
    } else {
        return false;
    }
})
export const validaSenha = ((senha) => {
    var senhaValida = (
        validaTamanhoSenha(senha) &&
        validaLetrasSenha(senha) &&
        validaNumerosSenha(senha) &&
        validaCaractereEspecialSenha(senha)
    )
    if (senhaValida){
        return true;
    } else {
        return false;
    }
})