import Vue from 'vue'

const chave = 'mensagem'

export const salvaMensagem = ((mensagem, titulo, variante = 'success', componente = '$notify') => {
    localStorage.setItem(chave, JSON.stringify({
            mensagem, titulo, variante, componente
        })
    )
})

export const pegaMensagem = (() => {
    const item = localStorage.getItem(chave)
    if(item){
        const mensagem = JSON.parse(item)
        localStorage.removeItem(chave)
        Vue.prototype[mensagem.componente][mensagem.variante](
            mensagem.mensagem, 
            mensagem.titulo, 
        )
    }
})