export default {
    namespaced: true,
    state: {
        validator: null,
    },
    mutations: {
        atualizaValidator(state, validator){
            state.validator = validator
        },
    },
    actions: {
        carregaValidator({ commit }, validator){
            commit('atualizaValidator', validator)
        }
    },
}
