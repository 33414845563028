const Index = () => import('../views/Index')
const Interno = () => import('@/shared/components/layout/Interno')

const routes = [
    {
        path: '/configuracoes/certificado_digital',
        component: Interno,
        children: [
            {
                name: 'configurqacaoCertificadoDigital',
                path: '/',
                component: Index
            }
        ]
    }
]

export default routes
