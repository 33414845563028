const Interno = () => import('@/shared/components/layout/Interno')
const ListarFornecedores = () => import('@/domains/shared/cadastros/fornecedores/views/Listar')
const AdicionarFornecedor = () => import('@/domains/shared/cadastros/fornecedores/views/Adicionar')
const EditarFornecedor = () => import('@/domains/shared/cadastros/fornecedores/views/Editar')
const VisualizarFornecedor = () => import('@/domains/shared/cadastros/fornecedores/views/Visualizar')
const ImportarCadastrosFornecedores = () => import('@/domains/shared/cadastros/fornecedores/views/Importar')

const routes = [
    {
        path: '/fornecedores/:id?',
        component: Interno,
        children: [
            {
                name: 'listarFornecedores',
                path: '/fornecedores',
                component: ListarFornecedores,
            },
            {
                name: 'adicionarFornecedores',
                path: '/fornecedores/adicionar',
                component: AdicionarFornecedor,
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarFornecedores',
                path: '/fornecedores/editar/:id',
                component: EditarFornecedor,
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarFornecedores',
                path: '/fornecedores/visualizar/:id',
                component: VisualizarFornecedor,
            },
            {
                name: 'importarCadastrosFornecedores',
                path: '/fornecedores/importar_cadastros',
                component: ImportarCadastrosFornecedores,
            }
        ]
    },
]

export default routes
