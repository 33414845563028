const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarOrdensServicos',
                path: '/ordens_servicos',
                alias: '/ordens_servicos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarOrdemServico',
                path: '/ordens_servicos/adicionar',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarOrdemServico',
                path: '/ordens_servicos/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'complementarOrdemServico',
                path: '/ordens_servicos/complementar_os/:id',
                component: () => import('../views/Complementar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarOrdemServico',
                path: '/ordens_servicos/visualizar/:id',
                component: () => import('../views/Visualizar'),
                meta: {
                    tipoVisualizacao: 'orcamento'
                }
            },
            {
                name: 'agruparOrdemServico',
                path: '/ordens_servicos/agrupar',
                component: () => import('../views/Agrupar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'copiarOrdemServico',
                path: '/ordens_servicos/adicionar/:id',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            }
        ]
    },
    {
        name: 'impressaoOrdemServico',
        path: '/os/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'ordemProducaoOrdemServicoImpressao',
        path: '/ordens_servicos/imprimir_ordem_producao/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'imprimirEtiquetaOrdemServico',
        path: '/ordens_servicos/imprimir_etiqueta/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
]

export default routes
