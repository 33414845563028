const routes = [
    {
        path: '',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                path: '/integracoes',
                name: 'integracoesSistema',
                component: () => import('../views/Integracoes'),
                meta: {
                    fullScreen: true
                }
            },
        ]
    },
]
export default routes