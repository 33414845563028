const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarVendasServicos',
                path: '/vendas_servicos',
                alias: '/vendas_servicos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarVendaServico',
                path: '/vendas_servicos/adicionar',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarVendaServico',
                path: '/vendas_servicos/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarVendaServico',
                path: '/vendas_servicos/visualizar/:id',
                component: () => import('../views/Visualizar'),
                meta: {
                    tipoVisualizacao: 'orcamento'
                }
            },
            {
                name: 'agruparVendaServico',
                path: '/vendas_servicos/agrupar_vendas',
                component: () => import('../views/Agrupar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'copiarVendaServico',
                path: '/vendas_servicos/adicionar/:id',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'faturamentoParcialVendaServico',
                path: '/vendas_servicos/faturamento_parcial/:id',
                component: () => import('../views/FaturarParcialmente'),
                meta: {
                    fullScreen: true
                }
            }
        ]
    },
    {
        name: 'impressaoVendaServico',
        path: '/pedido/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'impressaoOrdemServico',
        path: '/vendas_servicos/imprimir_ordem_servico/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
]

export default routes
