const routes = [
    {
        path: '',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                path: '/notas_fiscais/index/:id',
                redirect: to => {
                    return { path: '/notas_fiscais', query: { id: to.params.id }};
                }
            },
            {
                name: 'listarNotaFiscal',
                path: '/notas_fiscais',
                alias: '/notas_fiscais/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarNotaFiscal',
                path: '/notas_fiscais/adicionar',
                component: () => import('../views/Adicionar'),
                meta: { fullScreen: true }
            },
            {
                name: 'adicionarPedidoNotaFiscal',
                path: '/notas_fiscais/adicionar/:hash',
                component: () => import('../views/Adicionar'),
                meta: { fullScreen: true }
            },
            {
                name: 'editarVendaNotaFiscal',
                path: '/notas_fiscais/editar/:id',
                component: () => import('../views/Editar'),
                meta: { fullScreen: true }
            },
            {
                name: 'visualizarVendaNotaFiscal',
                path: '/notas_fiscais/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            {
                name: 'cartasCorrecoes',
                path: '/notas_fiscais/listar_cartas_correcoes',
                alias: ['/notas_fiscais/listar_cartas_correcoes/index'],
                component: () => import('../views/CartasCorrecoes')
            },
            {
                name: 'inutilizacoesNumeracoesNotaFiscal',
                path: '/notas_fiscais/listar_inutilizacoes_numeracoes',
                alias: ['/notas_fiscais/listar_inutilizacoes_numeracoes/index'],
                component: () => import('../views/InutilizacoesNumeracoes')
            },
            {
                name: 'importarXml',
                path: '/notas_fiscais/importar_xml',
                component: () => import('../views/ImportarXml')
            },
            {
                name: 'onboardingNotasFiscais',
                path: '/notas_fiscais/configuracoes',
                alias: '/notas_fiscais/tutorial',
                component: () => import('../views/Tutorial')
            },
        ]
    },
    {
        name: 'imprimirDanfeNotaFiscal',
        path: '/nfe/danfe/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        }
    },
    {
        name: 'imprimirDanfeSimplificadoNotaFiscal',
        path: '/nfe/danfe_simplificado/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        }
    },
    {
        name: 'imprimirCeeIdNotaFiscal',
        path: '/notas_fiscais/imprimir_cce/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'imprimirCeeHashNotaFiscal',
        path: '/carta-correcao/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'baixarXmlIdNotaFiscal',
        path: '/nfe/xml/:hash',
        component: () => import('@/shared/components/ui/Download'),
        meta: {
            public: true
        },
    },
    {
        name: 'baixarXmlHashNotaFiscal',
        path: '/notas_fiscais/baixar_xml/:id',
        component: () => import('@/shared/components/ui/Download'),
        meta: {
            public: true
        },
    },
    {
        name: 'visualizarXml',
        path: '/notas_fiscais/enviar_nota_fiscal/:id',
        component: () => import('../../shared/components/Lista/VisualizacaoXml'),
        meta: {
            public: true
        },
    },
]

export default routes
