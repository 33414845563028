const routes = [
    {
        path: '/tributacoes/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarTributacoes',
                path: '/tributacoes',
                alias: '/tributacoes/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarTributacao',
                path: '/tributacoes/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarTributacao',
                path: '/tributacoes/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarTributacao',
                path: '/tributacoes/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
