<template>
    <b-input-group class="flex-wrap flex-sm-nowrap">
        <div class="d-flex flex-column w-100">
            <b-form-input-date
                :value="start.value"
                :id="start.id"
                :name="start.name"
                :scope="scope"
                :required="required"
                :disabled="disabled"
                @input="changeStartDate($event)"
            />
            <div v-if="!isValidDate" class="d-block invalid-feedback">Data inicial deve ser menor que a final</div>
            <div v-if="!isValidMaxStartDate" class="d-block invalid-feedback">Data inicial não pode ser maior que a data de hoje</div>
        </div>
        <div class="input-group-btn pt-2 d-none d-sm-block">a</div>
        <div class="input-group-btn text-center p-2 w-100 d-block d-sm-none">a</div>
        <div class="d-flex flex-column w-100">
            <b-form-input-date :value="end.value" :id="end.id" :name="end.name" :scope="scope" :required="required" :disabled="disabled" @input="changeEndDate($event)" />
            <div v-if="!isValidDate" class="d-block invalid-feedback">Data final deve ser maior que a inicial</div>
        </div>
    </b-input-group>
</template>
<script>
export default {
    props: {
        start: {
            default: {
                id: null,
                name: null,
                value: null
            }
        },
        end: {
            default: {
                id: null,
                name: null,
                value: null
            }
        },
        required: {
            default: false
        },
        disabled: {
            default: false
        },
        scope: {
            default: null
        },
        maxStartDate: {
            default: null
        }
    },
    data() {
        return {
            isValidDate: true,
            isValidMaxStartDate: true,
        }
    },
    methods: {
        convertData(data) {
            return this.$moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
        validDates() {
            if (this.start.value && this.start.value.length == 10 && this.end.value && this.end.value.length == 10) {
                const dataInicio = new Date(this.convertData(this.start.value))
                const dataFim = new Date(this.convertData(this.end.value))
                if (dataInicio > dataFim) {
                    return false
                }
            }
            return true
        },
        validMaxStartDate() {
            const dataInicio = new Date(this.convertData(this.start.value))
            const maxStartDate = this.maxStartDate
            if (dataInicio > maxStartDate) {
                return false
            }
            return true
        },
        changeStartDate(date) {
            this.$emit('start', date)
            setTimeout(() => {
                this.isValidDate = this.validDates()
                if (!this.isValidDate) {
                    this.start.value = ''
                    this.$emit('start', '')
                }
                if (this.maxStartDate) {
                    this.isValidMaxStartDate = this.validMaxStartDate()
                    if (!this.isValidMaxStartDate) {
                        this.start.value = ''
                        this.$emit('start', '')
                    }
                }
            }, 100)
        },
        changeEndDate(date) {
            this.$emit('end', date)
            setTimeout(() => {
                this.isValidDate = this.validDates()
                if (!this.isValidDate) {
                    this.end.value = ''
                    this.$emit('end', '')
                }
            }, 100)
        }
    }
}
</script>
<style scoped>
.input-group {
    flex-wrap: unset;
}
.input-group-btn {
    padding-left: 13px;
    padding-right: 13px;
}
</style>
