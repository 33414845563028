import Vue from 'vue'
const moment = require('moment')
require('moment/locale/pt-br')

Vue.use(require('vue-moment'), {
    moment
})
import { formataData, formataDataAmericana, formataDataHora } from '../functions/data'
Vue.filter('formataDataHora', formataDataHora)
Vue.filter('formataData', formataData)
Vue.filter('formataDataAmericana', formataDataAmericana)
