import { Validator } from 'vee-validate'
import { validaEmail } from '@/shared/common/functions/texto'

Validator.localize('pt');

Validator.extend('email', {
    getMessage: 'Digite um e-mail válido',
    validate: ((email) => {
        return validaEmail(email)
    })
});
