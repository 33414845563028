const Interno = () => import('@/shared/components/layout/Interno')
const ListarClientes = () => import('@/domains/shared/cadastros/clientes/views/Listar')
const AdicionarCliente = () => import('@/domains/shared/cadastros/clientes/views/Adicionar')
const EditarCliente = () => import('@/domains/shared/cadastros/clientes/views/Editar')
const VisualizarCliente = () => import('@/domains/shared/cadastros/clientes/views/Visualizar')
const ImportarClientes = () => import('@/domains/shared/cadastros/clientes/views/Importar')

const routes = [
    {
        path: '',
        component: Interno,
        children: [
            {
                name: 'listarClientes',
                path: '/clientes',
                component: ListarClientes,
            },
            {
                name: 'adicionarClientes',
                path: '/clientes/adicionar',
                component: AdicionarCliente,
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarClientes',
                path: '/clientes/editar/:id',
                component: EditarCliente,
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarClientes',
                path: '/clientes/visualizar/:id',
                component: VisualizarCliente,
            },
            {
                name: 'importarCadastrosClientes',
                path: '/clientes/importar_cadastros',
                component: ImportarClientes,
            }
        ]
    },
]

export default routes
