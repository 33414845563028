const routes = [
    {
        name: 'linkCobranca',
        path: '/cobranca/:hash',
        component: () => import('../components/Cobranca'),
        meta: {
            fullScreen: true,
            public: true
        }
    },
    {
        name: 'visualizarPedido',
        path: '/pedidos/visualizar/:id',
        component: () => import('@/shared/components/ui/Visualizacao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'baixarXmlIdNotaFiscal',
        path: '/nfe/xml/:hash',
        component: () => import('@/shared/components/ui/Download'),
        meta: {
            public: true
        },
    },
    {
        name: 'baixarXmlIdNotaFiscalConsumidor',
        path: '/nfce/xml/:hash',
        component: () => import('@/shared/components/ui/Download'),
        meta: {
            public: true
        },
    },
    {
        name: 'baixarXmlIdNotaFiscalServico',
        path: '/nfse/xml/:hash',
        component: () => import('@/shared/components/ui/Download'),
        meta: {
            public: true
        },
    },
]
export default routes
