const routes = [
    {
        path: '/etiquetas/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarEtiquetas',
                path: '/etiquetas',
                alias: '/etiquetas/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarEtiqueta',
                path: '/etiquetas/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarEtiqueta',
                path: '/etiquetas/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarEtiqueta',
                path: '/etiquetas/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            {
                name: 'gerarEtiquetas',
                path: '/etiquetas/gerar_etiquetas',
                component: () => import('../views/GerarEtiquetas')
            }
        ]
    }
]

export default routes
