const routes = [
  {
    path: '/',
    component: () => import('@/shared/components/layout/Interno'),
    children: [
      {
        path: '/relatorios_ordens_servicos',
        component: () => import('../views/Listar')
      },
      {
          name: 'formularioRelatoriosOrdensServicos',
          path: '/relatorios_ordens_servicos/relatorio_ordens_servicos',
          component: () => import('../views/OrdemServico')
      },
      {
          name: 'formularioRelatoriosEquipamento',
          path: '/relatorios_ordens_servicos/relatorio_equipamentos',
          component: () => import('../views/Equipamento')
      },
      {
          name: 'formularioRelatoriosSituacaoTempo',
          path: '/relatorios_ordens_servicos/relatorio_tempo_situacao',
          component: () => import('../views/SituacaoTempo')
      },
      {
          name: 'formularioRelatoriosComissaoVendedor',
          path: '/relatorios_ordens_servicos/relatorio_comissao_vendedores',
          component: () => import('../views/ComissaoVendedor')
      },
      {
          name: 'formularioRelatoriosComissaoTecnico',
          path: '/relatorios_ordens_servicos/relatorio_comissao_tecnicos',
          component: () => import('../views/ComissaoTecnico')
      },  
    ]
  },
  {
      name: 'impressaoRelatorio',
      path: '/relatorios_ordens_servicos/:tipo/gerar',
      component: () => import('@/shared/components/ui/Relatorio')
  },
]
export default routes
