const routes = [
    {
        path: '/situacoes_locacoes/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarSituacoesLocacoes',
                path: '/situacoes_locacoes',
                alias: '/situacoes_locacoes/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarSituacaoLocacao',
                path: '/situacoes_locacoes/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarSituacaoLocacao',
                path: '/situacoes_locacoes/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarSituacaoLocacao',
                path: '/situacoes_locacoes/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
