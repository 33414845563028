import Vue from 'vue'
import Vuex from 'vuex'
import planos from './modules/planos'
import usuarios from './modules/usuarios'
import dominios from './modules/dominios'
import notificacoes from './modules/notificacoes'
import menus from './modules/menu'
import validator from './modules/validator'

Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        planos,
        usuarios,
        dominios,
        notificacoes,
        menus,
        validator,
    }
})
