const routes = [
  {
    path: '/',
    component: () => import('@/shared/components/layout/Interno'),
    children: [
      {
        path: '/relatorios_vendas',
        component: () => import('../views/Listar')
      },
      {
          name: 'formularioRelatoriosOrcamentos',
          path: '/relatorios_vendas/relatorio_orcamentos',
          component: () => import('../views/Orcamento')
      },
      {
          name: 'formularioRelatoriosVendas',
          path: '/relatorios_vendas/relatorio_vendas',
          component: () => import('../views/Venda')
      },
      {
          name: 'formularioRelatoriosProdutosVendidos',
          path: '/relatorios_vendas/relatorio_produtos_vendidos',
          component: () => import('../views/ProdutoVendido')
      },
      {
          name: 'formularioRelatoriosDevolucoes',
          path: '/relatorios_vendas/relatorio_devolucoes',
          component: () => import('../views/Devolucoes')
      },
      {
          name: 'formularioRelatoriosProdutosDevolvidos',
          path: '/relatorios_vendas/relatorio_produtos_devolvidos',
          component: () => import('../views/ProdutoDevolvido')
      },
      {
          name: 'formularioRelatoriosServicoPrestado',
          path: '/relatorios_vendas/relatorio_servicos_vendidos',
          component: () => import('../views/ServicoPrestado')
      },
      {
          name: 'formularioRelatoriosClienteMaisCompram',
          path: '/relatorios_vendas/relatorio_clientes_mais_compram',
          component: () => import('../views/ClienteMaisCompram')
      },
      {
          name: 'formularioRelatoriosComissaoPorVenda',
          path: '/relatorios_vendas/relatorio_comissao_vendedores',
          component: () => import('../views/ComissaoPorVenda')
      },
      {
          name: 'formularioRelatoriosComissaoPorProduto',
          path: '/relatorios_vendas/relatorio_comissao_produto',
          component: () => import('../views/ComissaoPorProduto')
      },
      {
          name: 'formularioRelatoriosComissaoPorServico',
          path: '/relatorios_vendas/relatorio_comissao_servico',
          component: () => import('../views/ComissaoPorServico')
      },
      
    ]
  },
  {
      name: 'impressaoRelatorio',
      path: '/relatorios_vendas/:tipo/gerar',
      component: () => import('@/shared/components/ui/Relatorio')
  },
]
export default routes