const routes = [
    {
        path: '/tipos_valores_produtos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarValoresProdutos',
                path: '/tipos_valores_produtos',
                alias: '/tipos_valores_produtos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarValorProduto',
                path: '/tipos_valores_produtos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarValorProduto',
                path: '/tipos_valores_produtos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarValorProduto',
                path: '/tipos_valores_produtos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
