const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                path: '/exclusao_dados',
                component: () => import('../views/ExcluirDados')
            }
        ]
    }
]

export default routes
