const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAjustes',
                path: '/ajustes_estoques',
                alias: '/ajustes_estoques/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarAjustes',
                path: '/ajustes_estoques/adicionar',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                path: '/ajustes_estoques/clonar/:id',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarAjustes',
                path: '/ajustes_estoques/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    },
    {
        name: 'imprimirAjustes',
        path: '/ajustes_estoques/imprimir_pdf/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
]

export default routes
