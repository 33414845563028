const routes = [
    {
        path: '/grades/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                path: '/grades',
                redirect: '/grades/index',
            },
            {
                name: 'listarGrades',
                path: '/grades/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarGrade',
                path: '/grades/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarGrade',
                path: '/grades/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarGrade',
                path: '/grades/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
