const routes = [
    {
        path: '/atributos_vendas/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAtributosVendas',
                path: '/atributos_vendas',
                alias: '/atributos_vendas/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarAtributoVenda',
                path: '/atributos_vendas/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarAtributoVenda',
                path: '/atributos_vendas/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarAtributoVenda',
                path: '/atributos_vendas/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
