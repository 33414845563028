const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarComprasServicos',
                path: '/compras_servicos',
                alias: '/compras_servicos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarCompraServicos',
                path: '/compras_servicos/adicionar',
                alias: '/compras_servicos/adicionar/cotacao:id',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarCompraServicos',
                path: '/compras_servicos/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarCompraServicos',
                path: '/compras_servicos/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
        ]
    },
]

export default routes
