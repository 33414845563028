const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarCaixas',
                path: '/caixas',
                alias: '/caixas/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'visualizarCaixa',
                path: '/caixas/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            {
                name: 'abrirCaixa',
                path: '/caixas/abrir_caixa',
                component: () => import('../views/Abrir')
            },
            {
                name: 'fecharCaixa',
                path: '/caixas/fechar_caixa/:id',
                component: () => import('../views/Fechar')
            },
            {
                name: 'sangriaCaixa',
                path: '/caixas/realizar_sangria/:id',
                component: () => import('../views/Sangria')
            },
            {
                name: 'reforcoCaixa',
                path: '/caixas/realizar_reforco/:id',
                component: () => import('../views/Reforco')
            },
        ],
    },
    {
        name: 'impressaoSangriaCaixa',
        path: '/caixas/imprimir_sangria/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'impressaoReforcoCaixa',
        path: '/caixas/imprimir_reforco/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'impressaoPdfCaixa',
        path: '/caixas/imprimir_pdf/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
]

export default routes
