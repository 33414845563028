const routes = [
    {
        path: '/atributos_movimentacoes_financeiras/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAtributosMovimentacoesFinanceiras',
                path: '/atributos_movimentacoes_financeiras',
                alias: '/atributos_movimentacoes_financeiras/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarAtributoMovimentacaoFinanceira',
                path: '/atributos_movimentacoes_financeiras/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarAtributoMovimentacaoFinanceira',
                path: '/atributos_movimentacoes_financeiras/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarAtributoMovimentacaoFinanceira',
                path: '/atributos_movimentacoes_financeiras/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
