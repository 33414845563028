const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarDevolucoesDeLocacoes',
                path: '/locacoes/devolucoes',
                component: () => import('../views/Listar')
            },
            {
                name: 'visualizarDevolucoesDeLocacoes',
                path: '/locacoes/visualizar_devolucao/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    },
    {
        name: 'impressaoDevolucao',
        path: '/locacoes/imprimir_devolucao/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        }
    }
]

export default routes