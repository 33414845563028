const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'ListaArtigos',
                path: '/ajuda',
                alias: '/ajuda/index',
                component: () => import('../views/Listar'),
            },
            {
                name: 'ListaSecaoArtigo',
                path: '/ajuda/secao/:url',
                component: () => import('../views/Listar')
            },
            {
                name: 'VisualizarArtigo',
                path: '/ajuda/artigo/:url',
                component: () => import('../views/Visualizar')
            }

        ]
    }
]

export default routes
