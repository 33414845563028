const Interno = () => import('@/shared/components/layout/Interno')
const Visualizar = () => import('../views/Visualizar')
const Listar = () => import('../views/Listar')
const Editar = () => import('../views/Editar')
const Adicionar = () => import('../views/Adicionar')

const ListarProdutos = () => import('../views/ListarProdutos')
const ImportarProdutos = () => import('../views/ImportarProdutos')

const routes = [
    {
        path: '/balancas',
        component: Interno,
        children: [
            {
                path: '/balancas',
                alias: ['/balancas/index'],
                component: Listar
            },
            {
                path: '/balancas/adicionar',
                component: Adicionar
            },
            {
                path: '/balancas/editar/:id',
                component: Editar
            },
            {
                path: '/balancas/visualizar/:id',
                component: Visualizar
            },
            {
                path: '/balancas/produtos/:id',
                component: ListarProdutos
            },
            {
                path: '/balancas/importar_vinculos/:id',
                component: ImportarProdutos
            }
        ]
    }
]

export default routes
