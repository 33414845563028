import Vue from 'vue'
import $api from '@/shared/services/api'
import loading from '@/shared/plugins/loading'

export default {
    namespaced: true,
    state: {
        dominio: null,
    },
    getters: {
        pegaDominio(state){
            return state.dominio
        },
    },
    mutations: {
        atualizaDominio(state, dominio){
            state.dominio = dominio
        },
    },
    actions: {
        async carregaDominio({ commit }){
            const retorno = await $api.cGet(Vue.prototype.$urlBase + '/dominios/dados')
            if(retorno.status == 'success'){
                if(
                    retorno.data.Dominio.utiliza_https == 1
                    && 
                    window.location.protocol == 'http:'
                ){
                    loading.show()
                    location.href = location.href.replace('http', 'https')
                }
                if(
                    retorno.data.Dominio.utiliza_https == 0 
                    && 
                    window.location.protocol == 'https:'
                ){  
                    loading.show()
                    location.href = location.href.replace('https', 'http')
                }
                commit('atualizaDominio', retorno.data.Dominio)
            }else{
                Vue.prototype.$dialog.error(retorno.message || 'Oops! Ocorreu algum erro no sistema.')
            }
            return retorno
        }
    },
}
