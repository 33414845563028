import Vue from 'vue'
import { removeHtml } from '../functions/texto'

const primeiroNome = ((nome) => {
    return nome.split(' ')[0] || nome
})

Vue.filter('primeiroNome', primeiroNome)

export const primeiraLetraMaiuscula = texto => {
    if (typeof texto !== 'string') {
		return ''
	}
	return texto.charAt(0).toUpperCase() + texto.substr(1)
}

Vue.filter('primeiraLetraMaiuscula', primeiraLetraMaiuscula)

Vue.filter('removeHtml', removeHtml)
