import Vue from 'vue'
import { somenteNumeros } from './global'
Vue.directive('mascara-ean', {
    inserted: function (el, binding) {
        const limite = 14
        el.maxLength = limite
        el.addEventListener('keypress', function( e ) {
            return somenteNumeros(e) 
        }, true)
        el.style.textAlign = binding.value || 'right'
    }
})