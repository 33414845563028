const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarIntermediadores',
                path: '/intermediadores_transacoes',
                alias: '/intermediadores_transacoes/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarIntermediador',
                path: '/intermediadores_transacoes/adicionar',
                component: () => import('../views/Adicionar'),
            },
            {
                name: 'visualizarIntermediador',
                path: '/intermediadores_transacoes/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            {
                name: 'editarIntermediador',
                path: '/intermediadores_transacoes/editar/:id',
                component: () => import('../views/Editar'),
            },
        ]
    },
]
export default routes