const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarCompras',
                path: '/compras',
                alias: '/compras/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarCompra',
                path: '/compras/adicionar',
                alias: '/compras/adicionar/cotacao:id',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarCompra',
                path: '/compras/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarCompra',
                path: '/compras/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            {
                name: 'listarNotasCompras',
                path: '/compras/listar_notas_compras',
                component: () => import('../views/ListarNotas')
            },
            {
                name: 'ImportarXmlCompras',
                path: '/compras/importar_xml',
                component: () => import('../views/ImportarXml')
            },
            {
                name: 'gerarCompraCompra',
                path: '/compras/gerar_compra/:id',
                component: () => import('../views/GerarCompra'),
                meta: {
                    fullScreen: true
                }
            },
        ]
    },
    {
        name: 'imprimirCompra',
        path: '/compra/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            public: true,
            fullScreen: true
        }
    },
    {
        name: 'imprimirDanfeCompra',
        path: '/compras/imprimir_danfe/:id',
        component: () => import('@/shared/components/ui/Impressao'),
    },
    {
        name: 'visualizarTipoCompra',
        path: '/compras/visualizar_compra/:id',
        component: () => import('@/shared/components/ui/Visualizacao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
]

export default routes
