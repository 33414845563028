const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'meuPlano',
                path: '/planos/meu_plano',
                component: () => import('../views/MeuPlano')
            },
            {
                path: '/planos',
                redirect: '/planos/meu_plano'
            },
            {
                name: 'alterarDadosPlano',
                path: '/planos/alterar_dados',
                component: () => import('../views/AlterarDados')
            },
            {
                name: 'contratarDadosPlano',
                path: '/planos/contratar',
                component: () => import('../views/Contratar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'contratarDadosPlanoV2',
                path: '/planos_v2/contratar',
                component: () => import('../views/ContratarV2'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'resumoPlano',
                path: '/planos/resumo/:hash',
                component: () => import('../views/Resumo'),
            },
        ]
    },
    {
        name: 'assinaturaBoleto',
        path: '/assinatura/boleto',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'assinaturaBoletoId',
        path: '/b/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        path: '/gerar_boleto_pagamento',
        component: () => import('../components/SegundaVia/index'),
        meta: { public: true }
    },
]

export default routes
