const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAvisosEmail',
                path: '/avisos',
                alias: '/avisos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarAvisoEmail',
                path: '/avisos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarAvisoEmail',
                path: '/avisos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarAvisoEmail',
                path: '/avisos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
