<template>
    <section class="content-header">
        <h1>
            <i v-if="icone" :class="icone" />
            {{ titulo }}
        </h1>
        <ol class="breadcrumb">
            <li>
                <b-link v-if="itens.length > 0" :to="$urlBase == '' ? '/inicio' : $urlBase">
                    <i class="fa fa-dashboard" />
                    Início
                </b-link>
                <div v-else>
                    <span class="active">
                        <i class="fa fa-dashboard" />
                        Início
                    </span>
                </div>
            </li>
            <li v-for="(item, index) in itens" :key="index" :class="index == itens.length - 1 ? 'active' : ''">
                <template v-if="item.url">
                    <router-link :to="item.url">
                        {{ item.descricao }}
                    </router-link>
                </template>
                <template v-else>
                    {{ item.descricao }}
                </template>
            </li>
        </ol>
    </section>
</template>

<script>
export default {
    name: 'BarraTitulo',
    props: {
        titulo: {
            type: String,
            required: true
        },
        icone: {
            type: String,
            required: false,
            default: ''
        },
        itens: {
            type: Array,
            required: true
        }
    }
}
</script>
