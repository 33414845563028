<template>
    <div 
        class="user-panel"
    >
        <div 
            class="text-center image"
        >
            <a 
                :href="this.$urlBase + '/inicio'" 
                border="0"
            >
                <img 
                    :src="usuarioLogado.Loja.caminho_logomarca" 
                    width="150" 
                    :alt="usuarioLogado.Loja.nome"
                >                                
            </a>
        </div>
        <div>
            <template 
                v-if="Object.keys(usuarioLojas).length > 1"
            >
                <ul id="menu-seleciona-loja" class="width-100pc mb-0 text-center">
                    <b-nav-item-dropdown>
                        <template #button-content>
                            Logado na loja 
                            <strong>{{ usuarioLogado.Loja.nome_abreviado }}</strong>
                        </template>
                        <b-dropdown-item
                            v-for="(usuarioLoja, id) in usuarioLojasOrdenadas"
                            :key="id" 
                            class="text-left" 
                            :class="[
                                usuarioLogado.Loja.id == usuarioLoja.UsuariosLoja.loja_id 
                                ? 'active' 
                                : ''
                            ]"
                            @click.prevent="alterarLoja(usuarioLoja)"
                        >
                            <i class = "fa icon-store mr-2"></i>
                            {{ usuarioLoja.Loja.nome }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </ul>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            usuarioLogado: this.$usuarioLogado,
            usuarioLojas: this.$usuarioLogado.UsuarioLojas
        }
    },
    computed: {
        usuarioLojasOrdenadas() {
            return [...this.usuarioLogado.UsuarioLojas].sort((a, b) => {
                const nomeA = a.Loja.nome.toUpperCase();
                const nomeB = b.Loja.nome.toUpperCase();
                return nomeA.localeCompare(nomeB);
            });
        }
    },
    methods: {
        alterarLoja(loja){
            if(
                this.usuarioLogado.Loja.id == loja.UsuariosLoja.loja_id
            ){
                return this.$dialog.warning(
                    'Você já está logado na loja ' + loja.Loja.nome + '!'
                )
            }
            this.$dialog.confirm(
                'Deseja logar na ' 
                + 
                (this.$urlBase == '/contabilidade' 
                    ? 'contabilidade' 
                    : 'loja'
                ) 
                + ' ' 
                + loja.Loja.nome 
                + '?', {
                icon: 'fa icon-store',
            }).then(retorno => {
                if(retorno){
                    this.$loading.show()
                    this.$api.cPost(this.$urlBase + '/lojas/logar', {
                        'loja': loja.UsuariosLoja.loja_id
                    }).then(() => {
                        location.reload()
                    })
                }
            })
        }
    }
}
</script>
<style>
    #menu-seleciona-loja .dropdown-menu > .active > a, 
    #menu-seleciona-loja .dropdown-menu > .active > a:hover, 
    #menu-seleciona-loja .dropdown-menu > .active > a:focus{
        color: #ffffff !important;
    }
    
    #menu-lojas{
        max-width: 15ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>