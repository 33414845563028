const routes = [
    {
        path: '/atributos_compras/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAtributosCompras',
                path: '/atributos_compras',
                alias: '/atributos_compras/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarAtributoCompra',
                path: '/atributos_compras/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarAtributoCompra',
                path: '/atributos_compras/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarAtributoCompra',
                path: '/atributos_compras/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
