const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarVideosTutoriais',
                path: '/ajuda/videos_tutoriais',
                alias: '/videos_tutoriais/index',
                component: () => import('../views/Listar')

            },
            
        ]
    }
]

export default routes
