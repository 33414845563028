import Vue from 'vue'
import App from '@/App.vue'

// api
import '@/shared/services/api'

// store
import store from '@/shared/store'

// router
import router from '@/shared/router'

// plugins
import '@/shared/plugins'

// layout AdminLTE
import '@/shared/layouts/adminlte'

// filters
import '@/shared/common/filters'

import '@/shared/assets/css/main.scss'

// Configurações do módulo
Vue.prototype.$urlBase = ''
Vue.prototype.$urlExternalFiles = ''

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
