const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarCotacoes',
                path: '/cotacoes',
                alias: '/cotacoes/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarCotacoes',
                path: '/cotacoes/adicionar',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarCotacoes',
                path: '/cotacoes/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarCotacoes',
                path: '/cotacoes/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
        ]
    },
    {
        name: 'imprimirCotacao',
        path: '/cotacoes/imprimir_pdf/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        path: '/',
        component: () => import('@/shared/layouts/adminlte/components/Barra'),
        children: [ 
            {
                name: 'visualizarCotacaoFornecedor',
                path: '/cotacao/:token',
                component: () => import('../views/Cotacao'),
                meta: {
                    fullScreen: true,
                    public: true
                }
            }
        ]
    }
]

export default routes
