const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarContratosAssinaturas',
                path: '/servicos_recorrentes',
                alias: '/servicos_recorrentes/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarContratosAssinaturas',
                path: '/servicos_recorrentes/adicionar/:id?',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarContratosAssinaturas',
                path: '/servicos_recorrentes/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarContratosAssinaturas',
                path: '/servicos_recorrentes/visualizar/:id',
                component: () => import('../views/Visualizar'),
            }
        ]
    }
]

export default routes
