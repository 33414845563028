const routes = [
    {
        path: '/tabelas_rateios/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarTabelasRateios',
                path: '/tabelas_rateios',
                alias: '/tabelas_rateios/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarTabelaRateio',
                path: '/tabelas_rateios/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarTabelaRateio',
                path: '/tabelas_rateios/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarTabelaRateio',
                path: '/tabelas_rateios/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
