const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarRelatoriosEstoque',
                path: '/relatorios_estoque',
                alias: '/relatorios_estoque/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'formularioRelatoriosEstoqueCotacoes',
                path: '/relatorios_estoque/relatorio_cotacoes',
                component: () => import('../views/Cotacao')
            },
            {
                name: 'formularioRelatoriosEstoqueCompras',
                path: '/relatorios_estoque/relatorio_compras',
                component: () => import('../views/Compra')
            },
            {
                name: 'formularioRelatoriosEstoqueInventario',
                path: '/relatorios_estoque/relatorio_inventario_estoque',
                component: () => import('../views/Inventario')
            },
            {
                name: 'formularioRelatoriosEstoqueProdutos',
                path: '/relatorios_estoque/relatorio_estoque_produtos',
                component: () => import('../views/Estoque')
            },
            {
                name: 'formularioRelatoriosEstoqueProdutosComprados',
                path: '/relatorios_estoque/relatorio_produtos_comprados',
                component: () => import('../views/ProdutosComprados')
            },
            {
                name: 'formularioRelatoriosEstoqueServicosContratados',
                path: '/relatorios_estoque/relatorio_servicos_contratados',
                component: () => import('../views/ServicosContratados')
            }
        ]
    },
    {
        name: 'impressaoRelatorio',
        path: '/relatorios_estoque/:tipo/gerar',
        component: () => import('@/shared/components/ui/Relatorio')
    },
]

export default routes
