const routes = [
    {
        path: '',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                path: '/produtos',
                redirect: '/produtos/index',
            },
            {
                name: 'listarProdutos',
                path: '/produtos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarProduto',
                path: '/produtos/adicionar',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarProduto',
                path: '/produtos/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'clonarProduto',
                path: '/produtos/clonar/:id',
                component: () => import('../views/Clonar')
            },
            {
                name: 'visualizarProduto',
                path: '/produtos/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            {
                name: 'ajustarValores',
                path: '/produtos/ajustar_valores',
                component: () => import('../views/AjustarValores')
            },
            {
                name: 'edicaoEmMassa',
                path: '/produtos/ajustar_produtos',
                component: () => import('../views/EdicaoEmMassa')
            },
            {
                name: 'movimentacoesEstoqueProduto',
                path: '/produtos/movimentacoes_estoque/:id',
                component: () => import('../views/MovimentacoesEstoque')
            },
            {
                name: 'importarProduto',
                path: '/produtos/importar_cadastros',
                component: () => import('../views/Importar')
            },
            {
                name: 'PesquisaNcm',
                path: '/ncm/pesquisa_ncm',
                alias: '/ncm/pesquisa_ncm/index',
                component: () => import('@/domains/shared/notas_fiscais/auxiliares/ncm/views/PesquisaNcm')
            },
            {
                path: '/produtos/logs_valores_venda/:id',
                component: () => import('../views/VisualizarValoresVenda'),
             },
        ]
    }
]

export default routes
