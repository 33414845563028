var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"sidebar-menu"},_vm._l((_vm.menus),function(menu){return _c('li',{key:menu['Menu']['id'],class:menu['Menu']['classe_li']},[_c('b-link',{attrs:{"href":menu['Menu']['link'],"target":menu['Menu']['nova_janela'] == 1 
                ? '_blank' 
                : '_self'}},[_c('span',{class:menu['Menu']['icone']}),_vm._v(" "+_vm._s(menu['Menu']['nome'])+" "),(menu['Filho'])?_c('i',{staticClass:"fa fa-angle-left pull-right"}):_vm._e()]),(menu['Filho'])?_c('ul',{staticClass:"treeview-menu"},_vm._l((menu['Filho']),function(filho,chaveFilho){return _c('li',{key:filho['id'],class:filho['classe_li']},[_c('b-link',{class:filho['classe_a'],attrs:{"href":filho['link'],"target":filho['nova_janela'] == 1 
                        ? '_blank' 
                        : '_self'}},[_c('span',{staticClass:"fa",class:filho['icone']}),_vm._v(" "+_vm._s(filho['nome'])+" "),(menu['Filho'][chaveFilho]['Neto'])?_c('i',{staticClass:"fa fa-angle-left pull-right"}):_vm._e()]),(menu['Filho'][chaveFilho]['Neto'])?_c('ul',{staticClass:"treeview-menu"},_vm._l((menu['Filho'][chaveFilho]['Neto']),function(neto){return _c('li',{key:neto.id},[_c('b-link',{class:neto.classe_a,attrs:{"href":neto.link,"target":neto['nova_janela'] == 1 
                                ? '_blank' 
                                : '_self'}},[_c('span',{class:neto['icone']}),_vm._v(" "+_vm._s(neto['nome'])+" ")])],1)}),0):_vm._e()],1)}),0):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }