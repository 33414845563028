const routes = [
    {
        path: '/naturezas_operacoes/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarNaturezasOperacoes',
                path: '/naturezas_operacoes',
                alias: '/naturezas_operacoes/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarNaturezaOperacao',
                path: '/naturezas_operacoes/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarNaturezaOperacao',
                path: '/naturezas_operacoes/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarNaturezaOperacao',
                path: '/naturezas_operacoes/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
