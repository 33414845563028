const Visualizar = () => import('../views/Visualizar')
const Listar = () => import('../views/Listar')
const Editar = () => import('../views/Editar')
const Adicionar = () => import('../views/Adicionar')
const Interno = () => import('@/shared/components/layout/Interno')

const routes = [
    {
        path: '/canais_vendas',
        component: Interno,
        children: [
            {
                name: 'listarCanaisVendas',
                path: '/canais_vendas',
                alias: '/canais_vendas/index',
                component: Listar
            },
            {
                name: 'visualizarCanalVenda',
                path: '/canais_vendas/visualizar/:id',
                component: Visualizar
            },
            {
                name: 'adicionarCanalVenda',
                path: '/canais_vendas/adicionar',
                component: Adicionar
            },
            {
                name: 'editarCanalVenda',
                path: '/canais_vendas/editar/:id',
                component: Editar
            }
        ]
    }
]

export default routes
