import { Validator } from 'vee-validate'
import { validaMinDataHoje, validaAno } from '@/shared/common/functions/data'

Validator.localize('pt');

Validator.extend('min-data-hoje', {
    getMessage: 'A data não pode ser menor que hoje',
    validate: ((data) => {
        return validaMinDataHoje(data)
    })
});

Validator.extend('valida-ano', {
    getMessage: 'Ano inválido',
    validate: ((data) => {
        return validaAno(data)
    })
});
