export const somenteNumeros = ((e) => {
    let tecla
    if(window.event){
        tecla = e.keyCode;
    }else if (e.which){
        tecla = e.which;
    }else{
        tecla = 0;
    }
    if ( (tecla >= 48 && tecla <= 57) || tecla == 8 || tecla == 13 || e.ctrlKey){
        return true;
    }else{
        e.preventDefault()
    }
})

export const somenteNumerosVirgulaPonto = ((e) => {
    let tecla
    if(window.event){
        tecla = e.keyCode;
    }else if (e.which){
        tecla = e.which;
    }else{
        tecla = 0;
    }
    if ( (tecla >= 48 && tecla <= 57) || tecla == 44 || tecla == 46 || tecla == 8 || tecla == 13 || e.ctrlKey){
        return true;
    }else{
        e.preventDefault()
    }
})

export const somenteNumerosVirgula = ((e) => {
    let tecla
    if(window.event){
        tecla = e.keyCode;
    }else if (e.which){
        tecla = e.which;
    }else{
        tecla = 0;
    }
    if ( (tecla >= 48 && tecla <= 57) || tecla == 44 || tecla == 8 || tecla == 13 || e.ctrlKey){
        return true;
    }else{
        e.preventDefault()
    }
})

export const validaTeclas = ((e) => {
    const teclas = ['Backspace']
    if(teclas.includes(e.key)){
        return false
    }
    return true
})

export const bloqueiaEnter = ((e) => {
    if(e.key == 'Enter'){
        e.preventDefault()
    }
    return true
})
