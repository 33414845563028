<template>
    <div 
        v-if = "exibirAlerta" 
        class = "alert text-center" 
        :class = "[classe]"
        id="alerta-plano"
    >
        {{ mensagem }} 
        <a 
            :href = "linkBotao" 
            class = "ml-2 btn btn-success"
        >
            {{ mensagemBotao }}
        </a>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { removeMoeda } from '@/shared/common/functions/numero'
export default {
    data() {
        return {
            exibirAlerta: false,
            classe: 'alert-warning',
            mensagem: '',
            linkBotao: '/planos/contratar',
            mensagemBotao: 'Assine agora',
        }
    },
    computed: {
        ...mapState('planos', ['empresaPlano']),
    },
    watch:{
        empresaPlano(empresaPlano){
            if(empresaPlano){
                this.validaAlerta(empresaPlano)  
            }
        }
    },
    mounted() {
        this.carregaEmpresaPlano()
    },
    methods: {
        ...mapActions('planos', ['carregaEmpresaPlano']),
        validaAlerta(){
            if(this.$url.module == 'contabilidade' || this.$url.module == 'parceiro'){
                this.exibirAlerta = false;
                return
            }
            const vencimentoPlano = this.pegaVencimentoPlano()
            let dataAtualPlano = this.pegaDataAtualPlano(3);
            const valida =  (removeMoeda(this.empresaPlano.valor) == 0 || vencimentoPlano <= dataAtualPlano ? true : false)
            if(valida && this.$usuarioLogado.Empresa.tipo_cobranca != 'P'){
                this.exibirAlerta = true;
                if(removeMoeda(this.empresaPlano['valor']) == 0){
                    this.mensagem = 'Sua versão de demonstração ' +  this.mensagemExpiraPlano(vencimentoPlano);
                }else{
                    this.mensagem = 'Seu plano ' +  this.mensagemExpiraPlano(vencimentoPlano);
                    this.linkBotao = '/planos/contratar?hash=' + this.empresaPlano['hash'] + '&acao=pagamento';
                    this.mensagemBotao = 'Renovar agora';
                }
                dataAtualPlano = this.pegaDataAtualPlano(5);
                if(vencimentoPlano <= dataAtualPlano){
                    this.classe =  'alert-danger';
                }
            }
            this.$emit('validaAlerta', valida)
        },
        pegaVencimentoPlano(){
            return this.$moment(this.empresaPlano.data_vencimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
        pegaDataAtualPlano(dias = 3){
            return this.$moment().add((this.$app.dias_gratis - dias), 'days').format('YYYY-MM-DD')
        },
        mensagemExpiraPlano(data){
            const quantidadeDias = this.pegaQuantidadeDias(data);
            const dataAtual = this.$moment().format('YYYY-MM-DD');
            if(data > dataAtual && quantidadeDias == 1){
                return 'expira amanhã';
            }else if(data > dataAtual){
                return 'expira em ' + quantidadeDias + ' dia' + (quantidadeDias > 1 ? 's' : '');
            }else if(data == dataAtual){
                return 'expira hoje';
            }else if(data < dataAtual && quantidadeDias == 1){
                return 'expirou ontem';
            }else{
                return 'expirou há ' + quantidadeDias + ' dia' + (quantidadeDias > 1 ? 's' : '');
            }
        },
        pegaQuantidadeDias(data){
            const inicial = this.$moment().format('YYYY-MM-DD');
            const final = this.$moment(data, 'YYYY-MM-DD');
            return Math.abs(final.diff(inicial, 'days'));
        },
    }
}
</script>
<style scoped>
    div{
        margin:0; 
        padding: 5px 10px 5px 10px
    }
    a{
        margin-left: 10px
    }
    .alert{
        margin:0; 
        padding: 5px 10px 5px 10px
    }
</style>