const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAgendamento',
                path: '/agendamentos',
                alias: '/agendamentos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'listarAgendamentoId',
                path: '/agendamentos/index/:id',
                redirect: to => {
                    return { path: '/agendamentos/index/', query: { 
                            id: to.params.id 
                        } 
                    }
                },
            },
            {
                path: '/agendamentos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                path: '/agendamentos/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            {
                name: 'sincronizarGoogleAgenda',
                path: '/agendamentos/sincronizar_google_agenda',
                component: () => import('../views/SincronizarGoogleAgenda')
            },
            
        ]
    }
]

export default routes
