import Vue from 'vue'
import { somenteNumeros, validaTeclas } from './global'
import { formataCpf, formataCnpj, formataCpfCnpj } from '../../functions/numero' 

const limiteCpf = 14
const limiteCnpj = 18

Vue.directive('mascara-cpf', {
    inserted: function (el) {
        el.maxLength = limiteCpf
        el.addEventListener('keypress', function( e ) {
            return somenteNumeros(e) 
        }, true)
        el.addEventListener('focus', function() {
            el.setAttribute('placeholder', '___.___.___-__')
        }, true)
        el.addEventListener('blur', function() {
            el.setAttribute('placeholder', '')
        }, true)
        el.addEventListener('keyup', function(e) {
            if(validaTeclas(e)){
                e.target.value = formataCpf(e.target.value)
            }
        }, true)
        el.addEventListener('change', function(e) {
            e.target.value = formataCpf(e.target.value)
        })
    }
})

Vue.directive('mascara-cnpj', {
    inserted: function (el) {
        el.maxLength = limiteCnpj
        el.addEventListener('keypress', function( e ) {
            return somenteNumeros(e) 
        }, true)
        el.addEventListener('focus', function() {
            el.setAttribute('placeholder', '__.___.___/____-__')
        }, true)
        el.addEventListener('blur', function() {
            el.setAttribute('placeholder', '')
        }, true)
        el.addEventListener('keyup', function(e) {
            if(validaTeclas(e)){
                e.target.value = formataCnpj(e.target.value)
            }
        }, true)
        el.addEventListener('change', function(e) {
            e.target.value = formataCnpj(e.target.value)
        })
    }
})

Vue.directive('mascara-cpf-cnpj', {
    inserted: function (el) {
        el.maxLength = limiteCnpj
        el.addEventListener('keypress', function( e ) {
            return somenteNumeros(e) 
        }, true)
        el.addEventListener('focus', function() {
            el.setAttribute('placeholder', '___.___.___-__')
        }, true)
        el.addEventListener('blur', function() {
            el.setAttribute('placeholder', '')
        }, true)
        el.addEventListener('keyup', function(e) {
            e.target.value = formataCpfCnpj(e.target.value)
        }, true)
        el.addEventListener('change', function(e) {
            e.target.value = formataCpfCnpj(e.target.value)
        })
    }
})