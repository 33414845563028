const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarSituacoesDeCompras',
                path: '/situacoes_compras',
                alias: '/situacoes_compras',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarSituacoesDeCompras',
                path: '/situacoes_compras/adicionar',
                component: () => import('../views/Adicionar'),
            },
            {
                name: 'visualizarSituacoesDeCompras',
                path: '/situacoes_compras/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            {
                name: 'editarSituacoesDeCompras',
                path: '/situacoes_compras/editar/:id',
                component: () => import('../views/Editar'),
            },
        ]
    },
]
export default routes