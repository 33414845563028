const routes = [
    {
        path: '/situacoes_contratos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarSituacoesContratos',
                path: '/situacoes_contratos',
                alias: '/situacoes_contratos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarSituacaoContrato',
                path: '/situacoes_contratos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarSituacaoContrato',
                path: '/situacoes_contratos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarSituacaoContrato',
                path: '/situacoes_contratos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
