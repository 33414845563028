<template>
    <b-nav-item-dropdown class="user user-menu" right ref="navdropdown">
        <template #button-content>
            <div class="d-none d-sm-block">
                <i class="fa fa-user" />
                <span class="ml-2">
                    {{ usuarioLogado['nome'] }}
                    <i class="fa fa-caret-down" />
                </span>
            </div>
            <div class="d-block d-sm-none">
                <i class="fa fa-user" />
                <span class="ml-2">
                    {{ usuarioLogado['nome_abreviado'] }}
                    <i class="fa fa-caret-down" />
                </span>
            </div>
        </template>
        <li class="user-header bg-light-blue">
            <img :src="usuarioLogado['caminho_foto']" class="img-circle" :alt="usuarioLogado['nome']" />
            <p>
                {{ usuarioLogado['nome'] }}
                <small> Membro desde {{ usuarioLogado['cadastrado_em'] }} </small>
            </p>
        </li>
        <li class="user-footer">
            <div class="pull-left">
                <button type="button" class="btn btn-default btn-flat" @click="meusDados()">Meus dados</button>
            </div>
            <div class="pull-right">
                <a v-if="$route.meta.botaoSairPdv" :href="`${this.$urlBase}/vendas_balcao`" class="btn btn-default btn-flat"> Ir para ERP </a>
                <a v-else @click.prevent.stop="logout" class="btn btn-default btn-flat"> Sair </a>
            </div>
        </li>
    </b-nav-item-dropdown>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState('usuarios', ['usuarioLogado'])
    },
    methods: {
        ...mapActions('usuarios', {
            xLogout: 'logout'
        }),
        async logout() {
            this.$refs.navdropdown.hide()
            this.$loading.show()
            const logout = await this.xLogout()
            if (logout.status == 'success') {
                location.href = this.$urlBase + '/login'
            } else {
                this.$loading.hide()
                this.$dialog.error(logout.message)
            }
        },
        meusDados() {
            this.$refs.navdropdown.hide()
            this.$router.push(this.$urlBase + '/usuarios/meus_dados').catch(() => {})
        }
    }
}
</script>
<style scoped>
.navbar-nav .user-menu .dropdown-menu {
    margin: 0;
}
</style>
