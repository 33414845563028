const routes = [
    {
        path: '/situacoes_agendamentos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarSituacoesAgendamentos',
                path: '/situacoes_agendamentos',
                alias: '/situacoes_agendamentos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarSituacaoAgendamento',
                path: '/situacoes_agendamentos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarSituacaoAgendamento',
                path: '/situacoes_agendamentos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarSituacaoAgendamento',
                path: '/situacoes_agendamentos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
