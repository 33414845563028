const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarRelatoriosNotasFiscais',
                path: '/relatorios_notas_fiscais',
                alias: '/relatorios_notas_fiscais/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'formularioRelatoriosNfe',
                path: '/relatorios_notas_fiscais/relatorio_nfe',
                component: () => import('../views/Nfe')
            },
            {
                name: 'formularioRelatoriosNfce',
                path: '/relatorios_notas_fiscais/relatorio_nfce',
                component: () => import('../views/Nfce')
            },
            {
                name: 'formularioRelatoriosNfse',
                path: '/relatorios_notas_fiscais/relatorio_nfse',
                component: () => import('../views/Nfse')
            },
        ]
    },
    {
        name: 'impressaoRelatorio',
        path: '/relatorios_notas_fiscais/:tipo/gerar',
        component: () => import('@/shared/components/ui/Relatorio')
    },
]

export default routes
