const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAtualizacoes',
                path: '/atualizacoes',
                alias: '/atualizacoes/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'visualizarAtualizacao',
                path: '/atualizacoes/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            
        ]
    }
]

export default routes
