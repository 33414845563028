const routes = [
    {
        path: '',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                path: '/grupos_produtos',
                redirect: '/grupos_produtos/index',
            },
            {
                name: 'listarGruposProdutos',
                path: '/grupos_produtos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarGrupoProduto',
                path: '/grupos_produtos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarGrupoProduto',
                path: '/grupos_produtos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarGrupoProduto',
                path: '/grupos_produtos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
