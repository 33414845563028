import Vue from 'vue'
import $api from '@/shared/services/api'
export default {
    namespaced: true,
    state: {
        menus: null,
        visivel: true,
    },
    getters: {
        listaMenus(state){
            return state.menus
        }
    },
    mutations: {
        carregaMenus(state, menus){
            state.menus = menus
        },
    },
    actions: {
        carregaMenus({ commit }){
            const parametros = new URLSearchParams(window.location.search)
            const requisicao = $api.cGet(
                Vue.prototype.$urlBase + '/menus/lista', {
                    plugin: Vue.prototype.$url.module,
                    controller: Vue.prototype.$url.controller,
                    action: Vue.prototype.$url.action,
                    menu: parametros.get('menu')
                }
            )
            requisicao.then((res) => {
                if(res.status == 'success'){
                    if(res.data.length){
                        res.data.map(menu => {
                            if(
                                !['contabilidade'].includes(Vue.prototype.$url.module)
                                &&
                                menu.Menu.action == 'acessar_erp'
                            ){
                                menu.Menu.link = '/inicio'
                            }
                        })
                    }
                    commit('carregaMenus', res.data)
                }
            }).catch((erro) => {
                console.log(erro)
            });
            return requisicao
        },
    },
}
