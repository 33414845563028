const routes = [
    {
        path: '/assuntos_atendimentos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAssuntosAtendimentos',
                path: '/assuntos_atendimentos',
                alias: '/assuntos_atendimentos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarAssuntoAtendimento',
                path: '/assuntos_atendimentos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarAssuntoAtendimento',
                path: '/assuntos_atendimentos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarAssuntoAtendimento',
                path: '/assuntos_atendimentos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
