const Interno = () => import('@/shared/components/layout/Interno')
const ListarFuncionarios = () => import('@/domains/shared/cadastros/funcionarios/views/Listar')
const AdicionarFuncionario = () => import('@/domains/shared/cadastros/funcionarios/views/Adicionar')
const EditarFuncionario = () => import('@/domains/shared/cadastros/funcionarios/views/Editar')
const VisualizarFuncionario = () => import('@/domains/shared/cadastros/funcionarios/views/Visualizar')

const routes = [
    {
        path: '/funcionarios/:id?',
        component: Interno,
        children: [
            {
                name: 'listarFuncionarios',
                path: '/funcionarios',
                component: ListarFuncionarios,
            },
            {
                name: 'adicionarFuncionarios',
                path: '/funcionarios/adicionar',
                component: AdicionarFuncionario,
            },
            {
                name: 'editarFuncionarios',
                path: '/funcionarios/editar/:id',
                component: EditarFuncionario,
            },
            {
                name: 'visualizarFuncionarios',
                path: '/funcionarios/visualizar/:id',
                component: VisualizarFuncionario,
            }
        ]
    },
]

export default routes
