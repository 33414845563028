import Vue from 'vue'
export const removeCookies = () => {
    removeCookiesColunas()
    removeCookiesChat()
}

export const removeCookiesColunas = () => {
    const cookies = Vue.$cookies.keys()
    if (cookies.length === 0) {
        return
    }
    cookies.forEach(cookie => {
        if (cookie.startsWith('colunas')){
            Vue.$cookies.remove(cookie)
        }
    })
}

export const removeCookiesChat = () => {
    localStorage.removeItem('token_zendesk')
}