const routes = [
    {
        path: '/situacoes_vendas/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarSituacoesVendas',
                path: '/situacoes_vendas',
                alias: '/situacoes_vendas/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarSituacaoVenda',
                path: '/situacoes_vendas/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarSituacaoVenda',
                path: '/situacoes_vendas/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarSituacaoVenda',
                path: '/situacoes_vendas/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
