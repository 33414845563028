const routes = [
    {
        path: '/situacoes_ordens_servicos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarSituacoesOrdensServicos',
                path: '/situacoes_ordens_servicos',
                alias: '/situacoes_ordens_servicos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarSituacaoOrdemServico',
                path: '/situacoes_ordens_servicos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarSituacaoOrdemServico',
                path: '/situacoes_ordens_servicos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarSituacaoOrdemServico',
                path: '/situacoes_ordens_servicos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
