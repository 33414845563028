<template>
    <b-toast
        :key="key"
        v-model="visible" 
        :title="title"
        :variant="variant"
        :solid="true"
        :dismissible="true"
        :autoHideDelay="autoHideDelay"
        :noFade="noFade"
    >
        <template #toast-title>
            {{ title }}
        </template>
        <div v-html="message"/>
    </b-toast>
</template>
<script>
import Vue from 'vue'
export default {
    data(){
        return {
            key: 0,
            visible: false,
            noFade: false,
            variant: 'default',
            title: '',
            message: '',
            autoHideDelay: 5000,
        }
    },
    mounted(){
        Vue.prototype.$notify = {}
        const methods = [
            'success',  
            'error', 
            'warning', 
            'info', 
            'default', 
            'close',
        ]
        methods.forEach(method => {
            Vue.prototype.$notify[method] = this[method]
        })
    },
    methods:{
        reset: function() {
            let key = JSON.parse(JSON.stringify(this.key))
            Object.assign(this.$data, this.$options.data())
            key++
            this.key = key
        },
        merge(options = {}, defaultOptions = {}){
            options = Object.assign(defaultOptions, options)
            return options
        },
        open(message, title, options = {}){
            this.reset()
            this.visible = true
            this.title = title
            this.message = message
            for (let data in options) {
                this[data] = options[data];
            }
        },
        close(){
            this.visible = false
        },
        success(message, title = 'Sucesso', options = {}){
            const defaultOptions = {}
            defaultOptions['variant'] = 'success'
            options = this.merge(options, defaultOptions)
            this.open(message, title, options)
        },
        error(message, title = 'Erro', options = {}){
            const defaultOptions = {}
            defaultOptions['variant'] = 'danger'
            options = this.merge(options, defaultOptions)
            this.open(message, title, options)
        },
        warning(message, title = 'Atenção', options = {}){
            const defaultOptions = {}
            defaultOptions['variant'] = 'warning'
            options = this.merge(options, defaultOptions)
            this.open(message, title, options)
        },
        info(message, title = 'Informação', options = {}){
            const defaultOptions = {}
            defaultOptions['variant'] = 'info'
            options = this.merge(options, defaultOptions)
            this.open(message, title, options)
        },
        default(message, title = 'Informação', options = {}){
            const defaultOptions = {}
            defaultOptions['variant'] = 'default'
            options = this.merge(options, defaultOptions)
            this.open(message, title, options)
        },
    }
}
</script>
<style>
    .b-toaster {
        z-index: 9999 !important;
    }
</style>