import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faTachometerAlt,
    faUserFriends,
    faComments,
    faGlobeAmericas,
    faUserAlt,
    faUsers,
    faDesktop,
    faCaretDown,
    faCog,
    faCogs,
    faQuestionCircle,
    faExclamationTriangle,
    faTrashAlt,
    faHdd,
    faImages,
    faTv,
    faStarHalfAlt,
    faListAlt,
    faSyncAlt,
    faLightbulb,
    faBatteryThreeQuarters,
    faAngleDoubleRight,
    faSearch,
    faSuitcase,
    faSearchPlus,
    faPlusCircle,
    faArrowAltCircleRight,
    faSortDown,
    faCheck,
    faTimes
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
    faTachometerAlt,
    faUserFriends,
    faComments,
    faGlobeAmericas,
    faUserAlt,
    faUsers,
    faDesktop,
    faCaretDown,
    faCog,
    faCogs,
    faQuestionCircle,
    faExclamationTriangle,
    faTrashAlt,
    faHdd,
    faImages,
    faTv,
    faStarHalfAlt,
    faListAlt,
    faSyncAlt,
    faLightbulb,
    faBatteryThreeQuarters,
    faAngleDoubleRight,
    faSearch,
    faSuitcase,
    faSearchPlus,
    faPlusCircle,
    faArrowAltCircleRight,
    faSortDown,
    faCheck,
    faTimes
)
Vue.component('font-awesome-icon', FontAwesomeIcon)