const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarNotificacoes',
                path: '/notificacoes',
                alias: '/notificacoes/index',
                component: () => import('../views/Listar')
            },
        ]
    }
]

export default routes
