const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarOrcamentosProdutos',
                path: '/orcamentos_produtos',
                alias: '/orcamentos_produtos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarOrcamentoProduto',
                path: '/orcamentos_produtos/adicionar',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarOrcamentoProduto',
                path: '/orcamentos_produtos/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarOrcamentoProduto',
                path: '/orcamentos_produtos/visualizar/:id',
                component: () => import('../views/Visualizar'),
                meta: {
                    tipoVisualizacao: 'orcamento'
                }
            },
            {
                name: 'agruparOrcamentoProduto',
                path: '/orcamentos_produtos/agrupar_orcamentos',
                component: () => import('../views/Agrupar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'copiarOrcamentoProduto',
                path: '/orcamentos_produtos/adicionar/:id',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
        ]
    },
    {
        name: 'impressaoOrcamentoProduto',
        path: '/proposta/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
]

export default routes
