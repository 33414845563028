import Vue from 'vue'

const loading = {
    show(){
        document.getElementById('loading').style.display = 'block';
    },
    hide(){
        document.getElementById('loading').style.display = 'none';
    }
}

Vue.prototype.$loading = loading

export default loading
