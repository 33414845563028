<template>
    <section 
        class="bg-black section-externo"
    >
        <b-container 
            fluid 
            class="rounded"
        >
            <b-row 
                class="justify-content-center"
            >
                <div 
                    id="login-box" 
                    class="form-box"
                    :class="classe"
                >
                    <!-- Header (Cabeçalho) -->
                    <div 
                        class="text-center header"
                    >
                        <a 
                            :href="this.$urlBase"
                        >
                            <img 
                                :src="this.$app.caminho_logomarca"
                                :title="this.$app.nome" 
                                style="height: 45px"
                                v-cloak
                            />
                        </a>
                    </div>
                    
                    <!-- Body (Corpo) -->
                    <div :class="bgColor">
                        <slot></slot>
                    </div>
                    
                    <!-- Footer (Rodapé) -->
                    <div 
                        v-if="redeSocial"
                        class="text-center follow-us"
                    >
                        <span>Siga-nos nas redes sociais</span>
                        <a 
                            v-for="rede in redesSociais" 
                            v-show="rede.habilitado" 
                            :key="rede.nome" 
                            :href="rede.link" 
                            target="_blank" 
                            class="mt-2 btn btn-circle" 
                            :class="rede.classe"
                        >
                            <i 
                                :class="rede.icone"
                            />
                        </a>
                    </div>
                </div>
            </b-row>
        </b-container>
    </section>
</template>
<script>
import generico from '../mixins/generico'
export default {
    mixins: [
        generico
    ],
    props: {
        redeSocial: {
            default: true
        },
        // cols: { 
        //     default: () => ({ 
        //         xs: '12', 
        //         sm: 'auto', 
        //         md: 'auto', 
        //         lg: 'auto' 
        //     })
        // },
        classe: {
            default: ''
        },
        bgColor: { 
            default: 'bg-white' 
        }
    },
    data(){
        return {
            carregado: false,
            redesSociais: [
                {
                    nome: 'YoutTube',
                    classe: 'bg-red',
                    icone: 'fa fa-youtube',
                    link: this.$app.youtube,
                    habilitado: this.$app.youtube ? true : false
                },
                {
                    nome: 'Facebook',
                    classe: 'bg-blue',
                    icone: 'fa fa-facebook',
                    link: this.$app.facebook,
                    habilitado: this.$app.facebook ? true : false
                },
                {
                    nome: 'Instagram',
                    classe: 'bg-purple',
                    icone: 'fa fa-instagram',
                    link: this.$app.instagram,
                    habilitado: this.$app.instagram ? true : false
                },
                {
                    nome: 'Twitter',
                    classe: 'bg-aqua',
                    icone: 'fa fa-twitter',
                    link: this.$app.twitter,
                    habilitado: this.$app.twitter ? true : false
                },
                {
                    nome: 'Linkedin',
                    classe: 'bg-blue',
                    icone: 'fa fa-linkedin',
                    link: this.$app.linkedin,
                    habilitado: this.$app.linkedin ? true : false
                },
            ]
        }
    },
    mounted() {
        document.title = this.$app.nome
        if(
            this.$url.module == 'contabilidade' 
            && 
            this.$app.logomarca_contabilidade
        ){
            this.$app.caminho_logomarca = this.$app.caminho_logomarca.replace(
                this.$app.logomarca,
                this.$app.logomarca_contabilidade 
            )
        }
    },
}
</script>
<style scoped>
    html, body, #app {
        min-height: 100vh !important;
    }
    .section-externo {
        min-height: 100vh !important;
        padding-bottom: 100px;
    }
    .logo {
        height: 50px !important
    }
    .bg-black {
        background-color: #222222 !important;
    }
    .follow-us span{
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }
    a.btn.btn-circle{
        line-height: 25px;
        padding-left: 1px;
    }
</style>