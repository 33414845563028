const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarOrcamentosServicos',
                path: '/orcamentos_servicos',
                alias: '/orcamentos_servicos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarOrcamentoServico',
                path: '/orcamentos_servicos/adicionar',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarOrcamentoServico',
                path: '/orcamentos_servicos/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarOrcamentoServico',
                path: '/orcamentos_servicos/visualizar/:id',
                component: () => import('../views/Visualizar'),
                meta: {
                    tipoVisualizacao: 'orcamento'
                }
            },
            {
                name: 'agruparOrcamentoServico',
                path: '/orcamentos_servicos/agrupar_orcamentos',
                component: () => import('../views/Agrupar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'copiarOrcamentoServico',
                path: '/orcamentos_servicos/adicionar/:id',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
        ]
    },
    {
        name: 'impressaoOrcamentoServico',
        path: '/orcamento/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
]

export default routes
