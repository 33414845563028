import Vue from 'vue'
import $api from '@/shared/services/api'
export default {
    namespaced: true,
    state: {
        notificacoesNovas: 0,
        notificacoes: [],
        notificacoesCarregadas: false,
        carregandoNotificacoes: false
    },
    getters: {
        listaNovasNotificacoes(state){
            return state.notificacoesNovas
        },
        listaNotificacoes(state){
            return state.notificacoes
        },
        notificacoesCarregadas(state){
            return state.notificacoesCarregadas
        }
    },
    mutations: {
        atualizaNovasNotificacoes(state, notificacoesNovas){
            state.notificacoesNovas = notificacoesNovas
            state.notificacoesCarregadas = true
        },
        atualizaNotificacoes(state, notificacoes){
            state.notificacoes = notificacoes
            state.notificacoesCarregadas = true
            state.carregandoNotificacoes = false
            state.notificacoesNovas = 0
        },
    },
    actions: {
        carregaNovasNotificacoes({ state, commit }){
            state.notificacoesCarregadas = false
            $api.cGet(
                Vue.prototype.$urlBase 
                + '/notificacoes/novas_notificacoes'
            ).then((res) => {
                if(res.status == 'success'){
                    commit('atualizaNovasNotificacoes', res.data)
                }
            }).catch((erro) => {
                console.log(erro)
            });
        },
        carregaNotificacoes({ state, commit }){
            // if(!state.notificacoesCarregadas){
                state.carregandoNotificacoes = true
                $api.cGet(
                    Vue.prototype.$urlBase
                    + '/notificacoes/ultimas_notificacoes'
                ).then((res) => {
                    if(res.status == 'success'){
                        commit('atualizaNotificacoes', res.data.notificacoes)
                    }
                }).catch((erro) => {
                    console.log(erro)
                });
            // }
        },
    },
}
