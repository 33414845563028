<template>
    <b-nav-item-dropdown
        right
        ref="navdropdown"
        id="topo-notificacoes"
        class="notifications-menu"
        @show="carregaNotificacoes"
    >
            <template #button-content>
                <i class="fa fa-warning" />
                <span 
                    v-if="notificacoesNovas > 0"
                    class="badge badge-warning" 
                >
                    {{ notificacoesNovas }}
                </span>
            </template>
            <template v-if="carregandoNotificacoes">
                <li class="text-center">
                    <a href="#">
                        <img src="/img/carregando_mini.gif">
                    </a>
                </li>  
            </template>    
            <template v-else-if="notificacoes.length > 0">
                <li class="header">
                    <b>Notificações</b>
                </li>
                <li>
                    <ul class="menu">
                        <li 
                            v-for="notificacao in notificacoes" 
                            :key="notificacao.Notificacao.id"
                        >
                            <a :href="'/' 
                                + (notificacao.Notificacao.plugin 
                                    ? notificacao.Notificacao.plugin + '/' 
                                    : ''
                                ) 
                                + notificacao.Notificacao.controller 
                                + '/' 
                                + notificacao.Notificacao.action 
                                + '/' 
                                + notificacao.Notificacao.registro_id
                                + (notificacao.Notificacao.referencia 
                                    ? `?referencia=${notificacao.Notificacao.referencia}`
                                    : ''
                                )"
                                @click="$refs.navdropdown.hide()"
                            >
                                <p 
                                    :class="(notificacao.Notificacao.nova == 1 
                                        ? 'bold' 
                                        : ''
                                    )"
                                >
                                    {{ notificacao.Notificacao.descricao }} 
                                </p>
                                <small class="text-muted">
                                    <i class="fa fa-clock-o" />
                                    {{ notificacao.Notificacao.cadastrado_em }}
                                </small>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="footer">
                    <a href="/notificacoes">
                        Ver todas as notificações
                    </a>
                </li>
            </template>
            <template v-if="!carregandoNotificacoes && notificacoes.length < 1">   
                <li class="header">
                    Você não tem notificações
                </li>
            </template>
    </b-nav-item-dropdown>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    watch: {
        carregandoNotificacoes (valor) {
            console.log(valor)
            this.carregado = valor
        }
    },
    data () {
        return {
            carregado: false
        }
    },
    computed: {
        ...mapState('notificacoes', ['notificacoesNovas', 'carregandoNotificacoes', 'notificacoesCarregadas', 'notificacoes']),
    },
    mounted(){
        this.carregaNovasNotificacoes()
    },
    methods: {
        ...mapActions('notificacoes', ['carregaNovasNotificacoes', 'carregaNotificacoes']),
    }
}
</script>
<style scoped>
    .dropdown-menu li a {
        display: block;
        clear: both;
        font-weight: normal;
        line-height: 1.428571429;
        color: #333333;
    }
</style>