<template>
    <b-input-group ref="input-date" class="input-group-date bform-input-date" :class="[{ 'position-inherit': showOverflow }, { 'position-relative': !showOverflow }]">
        <slot>
            <input
                autocomplete="off"
                type="text"
                :value="value"
                :id="dId"
                :name="dName"
                v-mascara-data
                :disabled="disabled"
                v-validate="(required ? 'required|' : '') + 'date_format:dd/MM/yyyy|valida-ano'"
                maxlength="10"
                class="form-control"
                :class="[{ error: errors.first((scope ? scope + '.' : '') + dName) }]"
            />
        </slot>
        <b-input-group-append>
            <b-button id="button" variant="default" :disabled="disabled" @click="mostraCalendario($event)">
                <svg
                    viewBox="0 0 16 16"
                    width="1em"
                    height="1em"
                    focusable="false"
                    role="img"
                    aria-label="calendar"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi-calendar b-icon bi"
                >
                    <g>
                        <path
                            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                        />
                    </g>
                </svg>
            </b-button>
        </b-input-group-append>
        <div v-show="show" class="calendario-dropdow" id="calendario" :class="reposiciona_calendario ? 'reposiciona_calendario' : null">
            <b-calendar @context="onContext" :value="formataValue" label-help="" :hide-header="true" @selected="selecionaCalendario($event)" />
        </div>
    </b-input-group>
</template>
<script>
import '@/shared/common/directives/mascaras'
export default {
    inject: ['$validator'],
    props: {
        id: {
            default: ''
        },
        name: {
            default: ''
        },
        value: {
            default: ''
        },
        disabled: {
            default: false
        },
        required: {
            default: false
        },
        scope: {
            default: null
        },
        displayModal: {
            default: 'sm'
        },
        showOverflow: {
            default: false
        }
    },
    data() {
        return {
            dId: this.id || this.name,
            dName: this.name || this.id,
            dValue: this.value,
            input: null,
            show: false,
            reposiciona_calendario: false,
            context: null
        }
    },
    computed: {
        formataValue() {
            return this.$moment(this.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
        }
    },
    mounted() {
        this.input = this.$refs['input-date'].querySelector('input')
        this.input.addEventListener('click', this.mostraCalendario)
        this.input.addEventListener('focus', this.mostraCalendario)
        this.input.addEventListener('input', this.atualizaInput)
        this.input.addEventListener('keydown', (e) => {
            this.onKeydown(e)
        })
        document.querySelector('.b-calendar-grid').addEventListener('keydown', (e) => {
            this.onKeydownCalendar(e)
        })
        window.addEventListener('click', (e) => {
            if (/button/i.test(e.target.tagName)) {
                const parent = e.target.parentNode.parentNode
                const input = parent.querySelector('input')
                if (parent && input && input.id != this.input.id) {
                    this.fechaCalendario()
                }
            }
            if (this.show && e.target.id != this.input.id && e.target.closest('#calendario') == null && e.target.closest('#button') == null) {
                this.fechaCalendario()
            }
        })
    },
    methods: {
        mostraCalendario(event) {
            const largura_da_janela = window.outerWidth
            const limite_a_direita = 200
            const posicao = event.x
            this.reposiciona_calendario = posicao > largura_da_janela - limite_a_direita ? true : false
            this.show = true
        },
        fechaCalendario() {
            this.show = false
        },
        atualizaInput(event) {
            this.$emit('input', event.target.value)
        },
        selecionaCalendario(data) {
            this.errors.remove((this.scope ? this.scope + '.' : '') + this.dName)
            if (data) {
                this.input.classList.remove('error')
            }
            const value = data ? this.$moment(data).format('DD/MM/YYYY') : this.dValue
            this.$emit('input', value)
            this.fechaCalendario()
        },
        onKeydown(e) {
            if (e.code == 'ArrowDown' || e.code == 'ArrowUp' || e.code == 'ArrowLeft' || e.code == 'ArrowRight') {
                document.querySelector('.b-calendar-grid').focus()
            }
            if (e.code == 'Tab') {
                this.fechaCalendario()
            }
        },
        onKeydownCalendar(e) {
            if (e.code == 'Tab') {
                this.$emit('input', this.$moment(this.context.activeYMD).format('DD/MM/YYYY'))
                this.fechaCalendario()
            }
        },
        onContext(ctx) {
            this.context = ctx
        }
    }
}
</script>
<style scoped>
.calendario-dropdow {
    position: absolute;
    margin-top: 40px;
    text-align: center;
    background: #fff;
    padding: 0.5rem;
    -webkit-box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);
    z-index: 2300;
}
.reposiciona_calendario {
    right: 0;
}
.input-group.position-inherit {
    position: inherit !important;
}
svg {
    pointer-events: none;
}
</style>
