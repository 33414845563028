var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-input-group',{staticClass:"input-group-date"},[_vm._t("default",function(){return [_c('input',{directives:[{name:"mascara-horario",rawName:"v-mascara-horario"},{name:"validate",rawName:"v-validate",value:(
                (_vm.required ? 'required|' : '')
            ),expression:"\n                (required ? 'required|' : '')\n            "}],staticClass:"form-control",class:[
                {
                    error: _vm.errors.first(
                        (_vm.scope ? _vm.scope + '.' : '') 
                        + _vm.dName
                    )
                }
            ],attrs:{"id":_vm.dId,"name":_vm.dName,"disabled":_vm.disabled,"maxlength":"10"},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":function($event){return _vm.changeTime($event.target.value)}}})]}),_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"value":_vm.value,"labelNoTimeSelected":"------","button-only":"","right":"","locale":"pt","aria-controls":_vm.dName,"button-variant":"default","no-close-button":"","autocomplete":"off"},on:{"input":_vm.changeTimePicker}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }