const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarRelatoriosFinanceiros',
                path: '/relatorios_financeiros',
                alias: '/relatorios_financeiros/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'formularioRelatoriosExtrato',
                path: '/relatorios_financeiros/relatorio_extrato',
                component: () => import('../views/Extrato')
            },
            {
                name: 'formularioContaPagar',
                path: '/relatorios_financeiros/relatorio_contas_pagar',
                component: () => import('../views/ContaPagar')
            },
            {
                name: 'formularioContaReceber',
                path: '/relatorios_financeiros/relatorio_contas_receber',
                component: () => import('../views/ContaReceber')
            },
            {
                name: 'formularioCentroCusto',
                path: '/relatorios_financeiros/relatorio_centros_custos',
                component: () => import('../views/CentroCusto')
            },
            {
                name: 'formularioComissaoVendedor',
                path: '/relatorios_financeiros/relatorio_comissao_vendedores',
                component: () => import('../views/ComissaoVendedor')
            },
            {
                name: 'formularioDre',
                path: '/relatorios_financeiros/relatorio_dre',
                component: () => import('../views/Dre')
            },
            {
                name: 'formularioControleCheque',
                path: '/relatorios_financeiros/relatorio_controle_cheques',
                component: () => import('../views/ControleCheque')
            },
            {
                name: 'formularioContaBancaria',
                path: '/relatorios_financeiros/relatorio_contas_bancarias',
                component: () => import('../views/ContaBancaria')
            },
        ]
    },
    {
        name: 'impressaoRelatorio',
        path: '/relatorios_financeiros/:tipo/gerar',
        component: () => import('@/shared/components/ui/Relatorio')
    },
]

export default routes
