<template>
    <div 
        v-if="carregado" 
        class="skin-blue"
    >
        <alerta @validaAlerta="exibirAlertaPlano = $event"/>
        <slot name="topo">
            <topo/>
        </slot>
        <div class="wrapper row-offcanvas row-offcanvas-left">
            <aside 
                v-if="!menuDesabilitado"
                class="left-side sidebar-offcanvas"
            >
                <section class="sidebar mb-0">
                    <loja/>
                    <Menu/>
                </section>
            </aside>
            <aside 
                class="right-side"
            >
                <slot></slot>
            </aside>
        </div>
        <!-- <div id="botao_suporte_zendesk">
            <div id="contagem_mensagens"></div>
            <button :style="`background: #${$app.cor_primaria}`"> Suporte ao sistema </button>
        </div> -->
    </div>
</template>
<script>
import Vue from 'vue'
import generico from '../mixins/generico'
import interno from '../mixins/interno'
import Alerta from './Topo/Alerta'
import Topo from './Topo'
import Loja from './Loja'
import Menu from './Menu'
import { salvaMensagem } from '@/shared/common/functions/mensagens'
import { mapState, mapActions } from 'vuex'
Vue.mixin(interno)
export default {
    components: {
        Alerta, Topo, Loja, Menu
    },
    mixins: [
        generico, interno
    ],
    mounted: function(){
        this.carregaMenus()
        this.atualizaDadosNavegacao()
        this.atualizaTitulo()
        this.validaEmissor()
        this.validaLogomarca()
    },
    computed: {
        menuDesabilitado(){
            return this.$route.meta.menuDisabled || false
        },
        ...mapState('menus', ['menus']),
        ...mapState('usuarios', ['usuarioLogado']),
        carregado(){
            return this.usuarioLogado 
                && this.menus 
                && this.emissorValidado
        },
    },
    data(){
        return {
            exibirAlertaPlano: false,
            emissorValidado: false
        }
    },
    watch:{
        '$route'() {
            this.atualizaTitulo()
            this.validaEmissor()
        },
        usuarioLogado(novoUsuarioLogado, usuarioLogado){
            if(novoUsuarioLogado && usuarioLogado){
               this.validaLojaAtual(novoUsuarioLogado, usuarioLogado)
            }
            this.validaEmissor()
        },
        menuDesabilitado(desabilitado){
            if(desabilitado){
                const rightSide = document.querySelector('.right-side')
                if(rightSide){
                    rightSide.classList.add('strech')
                }
            }
        },
        exibirAlertaPlano(exibir){
            if(exibir){
                const leftSide = document.querySelector('.left-side')
                if(leftSide){
                    leftSide.classList.add('left-side-demonstration')
                }
            }
        }
    },
    methods: {
        ...mapActions('menus', ['carregaMenus']),
        ...mapActions('usuarios', ['carregaUsuarioLogado']),
        ...mapActions('notificacoes', ['carregaNovasNotificacoes']),
        validaEmissor(){
            if(
                this.$url.module == 'contabilidade' 
                || 
                this.$url.module == 'parceiro'
            ){
                this.emissorValidado = true 
            }else{
                if(
                    this.usuarioLogado
                    &&
                    this.usuarioLogado.Empresa
                    &&
                    this.usuarioLogado.Empresa.modalidade
                ){
                    const modalidade = this.usuarioLogado.Empresa.modalidade
                    if(
                        this.$route.fullPath.indexOf('emissor') > 0
                        &&
                        modalidade != 'NFE'
                    ){
                        this.$loading.show()
                        window.location.href = '/inicio'
                    }
                    if(
                        this.$route.fullPath.indexOf('emissor') <= 0
                        &&
                        modalidade == 'NFE'
                    ){
                        this.$loading.show()
                        window.location.href = '/emissor' + this.$route.fullPath
                    }
                    this.emissorValidado = true
                }
            }
        },
        atualizaTitulo(){
            document.title = this.$app.nome
            let i = 0
            const limite = 10
            while (i <= limite) {
                setTimeout(() => {
                    const h1 = document.getElementsByTagName('h1')
                    if (h1.length && h1[0] !== undefined) {
                        document.title = (`${document.getElementsByTagName('h1')[0].innerText} - ${this.$app.nome}`)
                        i = limite
                    }
                }, i * 500)
                i++
            }
        },
        validaLojaAtual(novoUsuarioLogado, usuarioLogado){
            if(usuarioLogado.Loja.id == novoUsuarioLogado.Loja.id){
                return true
            }else{
                this.$dialog.warning(
                    'Você logou em outra empresa/loja usando uma outra guia do navegador, clique no botão abaixo para atualizar as informações!', {
                    buttons:[
                        {
                            name: 'Atualizar página',
                            variant: 'success',
                            value: true
                        }
                    ]
                }).then(() => {
                    this.$loading.show()
                    location.reload()
                })
                return false
            }
        },
        validaLogomarca(){
            if(
                this.$url.module == 'contabilidade' 
                && 
                this.$app.logomarca_contabilidade
            ){
                this.$app.caminho_logomarca = this.$app.caminho_logomarca.replace(
                    this.$app.logomarca,
                    this.$app.logomarca_contabilidade
                )
            }
        },
        atualizaDadosNavegacao(){
            let ativo = true
            window.onfocus = async () => {
                if(ativo == false){
                    const autenticacao = await this.carregaUsuarioLogado()
                    if((autenticacao && autenticacao.status == 'error')){
                        if(autenticacao.message){
                            salvaMensagem(autenticacao.message, 'Autenticação', 'error')
                        }
                        this.$loading.show()
                        location.href = this.$urlBase + '/login?retorno=' + encodeURIComponent(this.$route.fullPath)
                    }else{
                        this.carregaNovasNotificacoes()
                    }
                    this.fechaDropdownNotificacoes() 
                }
                ativo = true
            }
            window.onblur = () => {
                ativo = false
            }
        },
        fechaDropdownNotificacoes(){
            const menuNotificacoes = document.querySelector('#notifications-menu')
            if(menuNotificacoes && menuNotificacoes.classList.contains('show')){
                menuNotificacoes.classList.remove('show')
                menuNotificacoes.setAttribute('aria-expanded', false)
                document.querySelector('[aria-labelledby="notifications-menu"]').classList.remove('show')
            }
                                
        }
    }
}
</script>
<style scoped>
    #botao_suporte_zendesk {
        position:fixed;
        background:transparent;
        border:none;
        height: 30px;
        min-height:30px;
        margin:0;
        bottom: 0;
        right: 0;
        animation-duration: 0.5s;
        animation-name: alterarOpacidade;
    }
    #botao_suporte_zendesk button{
        padding: 5px 10px 10px 10px;
        border-radius:5px 5px 0 0;
        cursor: pointer;
        color:#fff;
        border:none;
    }
    @keyframes alterarOpacidade {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }
    #contagem_mensagens {
        display:none;
        position: absolute;
        top: -10px;
        left: -10px;
        background: #d93f4c;
        color: #fff;
        border-radius: 50%;
        padding: 5px;
        min-width: 20px;
        text-align: center;
        width: 23px;
        height: 22px;
        line-height: 12px;
    }
</style>