const routes = [
    {
        path: '/tipos_enderecos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarTiposEnderecos',
                path: '/tipos_enderecos',
                alias: '/tipos_enderecos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarTipoEndereco',
                path: '/tipos_enderecos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarTipoEndereco',
                path: '/tipos_enderecos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarTipoEndereco',
                path: '/tipos_enderecos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
