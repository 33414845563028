const routes = [
    {
        path: '/404',
        name: 'Error404',
        component: () => import('../views/Error404.vue')
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('../views/Error.vue')
    },
]
export default routes