import erros from '@/domains/shared/erros/router/routes'
//import dashboard from '@/domains/dashboard/router/routes'
//import { global } from '@/shared/router/global'
/* busca todas as rotas contidas em arquivos com o nome routes.js dentro do pasta domains */
let rotas = []
const paths = require.context('@/domains', true, /routes.js$/i)
const context = paths.keys().map(paths).map(m => m.default)

context.map((arr) => {
    if (arr) {
        arr.map((val) => {
            rotas.push(val)
        })
    }
})
/**** */

const routes = [
    ...erros,
    //...dashboard,
    //...global(null),
    ...rotas,
    { 
        path: '/forbidden-page',
        name: 'ForbiddenPage',
        component: () => import('@/shared/components/layout/PaginaForbidden')
    },
    { 
        path: '*', 
        component: () => import('@/shared/components/layout/PaginaNaoEncontrada')
    }
]

export default routes
