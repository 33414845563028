<template>
    <b-input-group
        class="input-group-date"
    >
        <slot>
            <input 
                :value="value"
                :id="dId"
                :name="dName"
                v-mascara-horario
                :disabled="disabled"
                v-validate="
                    (required ? 'required|' : '')
                "
                maxlength="10"
                class="form-control"
                :class="[
                    {
                        error: errors.first(
                            (scope ? scope + '.' : '') 
                            + dName
                        )
                    }
                ]"
                @input="$emit('input', $event.target.value)"
                @blur="changeTime($event.target.value)"
            >
        </slot>
        <b-input-group-append>
            <b-form-timepicker
                :value="value"
                labelNoTimeSelected="------"
                button-only
                right
                locale="pt"
                :aria-controls="dName"
                button-variant="default"
                no-close-button
                @input="changeTimePicker"
                autocomplete="off"
            ></b-form-timepicker>
        </b-input-group-append>
    </b-input-group>
</template>
<script>
import '@/shared/common/directives/mascaras'
import { formataHora } from '@/shared/common/functions/data'
export default {
    inject: ['$validator'],
    props: {
        id: {
           default: ''
        },
        name: {
           default: ''
        },
        value: {
           default: ''
        },
        disabled: {
            default: false
        },
        required: {
            default: false
        },
        scope: {
            default: null
        },
        displayModal: {
            default: 'sm'
        }
    },
    data(){
        return {
            dId: this.id || this.name,
            dName: this.name || this.id,
        }
    },
    methods: {
         showModal() {
            this.$refs['time'].show()
        },
        changeTimePicker(time){
            this.errors.remove(
                (this.scope ? this.scope + '.' : '') 
                + this.dName
            )
            this.$emit('input', formataHora(time))
        },
        changeTime(time) {
            let newTime = time.toString()
            let hour = ""
            let minute = ""
            if(newTime.length != 0 && newTime.length < 5) {
                if(newTime.length == 1) {
                     newTime = `0${newTime}`
                }
                if(newTime.indexOf(':') != -1) {
                    newTime = newTime.padEnd(5, '0')
                     hour = newTime.substring(0, 2)
                     minute = newTime.substring(3, 5)
                } else {
                    newTime = newTime.padEnd(4, '0')
                    hour = newTime.substring(0, 2)
                    minute = newTime.substring(2, 4)
                }
                newTime = `${hour}:${minute}`
            }
            this.$emit('input', newTime)
            this.$emit('change', newTime)
        },
    },
}
</script>