const routes = [
    {
        path: '/formas_atendimentos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarFormasAtendimentos',
                path: '/formas_atendimentos',
                alias: '/formas_atendimentos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarFormaAtendimento',
                path: '/formas_atendimentos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarFormaAtendimento',
                path: '/formas_atendimentos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarFormaAtendimento',
                path: '/formas_atendimentos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
