export default {
    pt: {
        messages: {
            cpf: () => 'Digite um CPF válido',
            cnpj: () => 'Digite um CNPJ válido',
            email: () => 'E-mail inválido',
            required: () => ' ',
            date_format: () => 'Data inválida',
            ip: () => 'IP inválido',
            min: (field, param) => 'Mínimo ' + param + ' caracteres',
            max: (field, param) => 'Máximo ' + param + ' caracteres',
            url: () => 'Insira uma URL válida!',
            confirmed: () => 'Confirmação inválida',
        }
    }
}
