const routes = [
    {
        path: '',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                path: '/marcas_produtos',
                redirect: '/marcas_produtos/index',
            },
            {
                name: 'listarMarcasProdutos',
                path: '/marcas_produtos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarMarcaProduto',
                path: '/marcas_produtos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarMarcaProduto',
                path: '/marcas_produtos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarMarcaProduto',
                path: '/marcas_produtos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
