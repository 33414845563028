<template>
    <ul class="sidebar-menu">
        <li 
            v-for="menu in menus" 
            :key="menu['Menu']['id']"
            :class="menu['Menu']['classe_li']" 
        >
            <b-link 
                :href="menu['Menu']['link']" 
                :target="menu['Menu']['nova_janela'] == 1 
                    ? '_blank' 
                    : '_self'
                "
            >
                <span :class="menu['Menu']['icone']"></span>
                {{ menu['Menu']['nome'] }}
                <i 
                    v-if="menu['Filho']" 
                    class="fa fa-angle-left pull-right"
                ></i>
            </b-link>
            <ul 
                v-if="menu['Filho']" 
                class="treeview-menu"
            >
                <li 
                    v-for="(filho, chaveFilho) in menu['Filho']" 
                    :class="filho['classe_li']" 
                    :key="filho['id']"
                >
                    <b-link 
                        :href="filho['link']" 
                        :class="filho['classe_a']" 
                        :target="filho['nova_janela'] == 1 
                            ? '_blank' 
                            : '_self'
                        "
                    >
                        <span class="fa" :class="filho['icone']"></span>
                        {{ filho['nome'] }}
                        <i 
                            v-if="menu['Filho'][chaveFilho]['Neto']" 
                            class="fa fa-angle-left pull-right"
                        ></i>
                    </b-link>
                    <ul 
                        v-if="menu['Filho'][chaveFilho]['Neto']" 
                        class="treeview-menu"
                    >
                        <li 
                            v-for="neto in menu['Filho'][chaveFilho]['Neto']" 
                            :key="neto.id"
                        >
                            <b-link 
                                :href="neto.link" 
                                :class="neto.classe_a" 
                                :target="neto['nova_janela'] == 1 
                                    ? '_blank' 
                                    : '_self'
                                "
                            >
                                <span :class="neto['icone']"></span>
                                {{ neto['nome'] }}
                            </b-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</template>
<script>
import { mapState } from 'vuex'
import { slideToggle } from '@/shared/common/functions/jquery/slide'
import { escondeMenu } from '../functions/menu'
export default {
    computed: {
        ...mapState('menus', ['menus']),
    },
    mounted(){
        this.montaMenu()
    },
    methods: {
        montaMenu(){
            const menus = document.querySelectorAll('.sidebar-menu .treeview')
            menus.forEach((treeview) => {
                let botao = treeview.querySelector('a') 
                let menu = treeview.querySelector('.treeview-menu') 
                let ativo = treeview.classList.contains('active')
                if(ativo){
                    menu.style.display = 'block'
                    let faLeft = botao.querySelector('.fa-angle-left')
                    if(faLeft){
                        faLeft.classList.remove('fa-angle-left')
                        faLeft.classList.add('fa-angle-down')
                    }
                }
                botao.addEventListener('click', (e) => {
                    e.preventDefault()
                    slideToggle(menu)
                    if (ativo) {
                        botao.parentNode.classList.remove('active')
                        ativo = false
                        botao.querySelector('.fa-angle-down').classList.add('fa-angle-left')
                        botao.querySelector('.fa-angle-left').classList.remove('fa-angle-down')
                    }else{
                        botao.parentNode.classList.add('active')
                        ativo = true
                        botao.querySelector('.fa-angle-left').classList.add('fa-angle-down')
                        botao.querySelector('.fa-angle-down').classList.remove('fa-angle-left')
                    }
                })
                menu.querySelectorAll('a').forEach((a) => {
                    let espacamento = parseInt(window.getComputedStyle(a).marginLeft) + 10
                    a.style.marginLeft = espacamento + 'px'
                })
            })
            if(this.$isMobile() || this.$route.meta.fullScreen){
                escondeMenu()
            }
            const alerta = document.querySelector('#alerta-plano')
            if(alerta){
                const leftSide = document.querySelector('.left-side')
                if(leftSide){
                    leftSide.classList.add('left-side-demonstration')
                }
            }
        }
    }
}
</script>