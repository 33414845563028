const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarBackupsDados',
                path: '/backup_dados',
                alias: '/backup_dados/index',
                component: () => import('../views/Listar')
            },
            {
                path: '/backup_dados/baixar/:id',
                component: () => import('../views/Listar')
            }
        ]
    }
]

export default routes
