import { Validator } from 'vee-validate'
import { validaEan, parcelaMinBoleto } from '@/shared/common/functions/numero'

Validator.localize('pt')

Validator.extend('ean', {
    getMessage: 'Digite um código válido',
    validate: ((ean) => {
        return validaEan(ean)
    })
});

Validator.extend('parcela-mim-boleto', {
    getMessage: 'O valor minimo da parcela é 20,00',
    validate: ((value) => {
        return parcelaMinBoleto(value)
    })
});
