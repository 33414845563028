const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'ConfigGerais',
                path: '/configuracoes/gerais',
                component: () => import('../views/ConfigGeraisView'),
            },
        ]
    }
]

export default routes