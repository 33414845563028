const routes = [
    {
        path: '/unidades_produtos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarUnidadesProdutos',
                path: '/unidades_produtos',
                alias: '/unidades_produtos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarUnidadeProduto',
                path: '/unidades_produtos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarUnidadeProduto',
                path: '/unidades_produtos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarUnidadeProduto',
                path: '/unidades_produtos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
