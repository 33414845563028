const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarCentrosDeCustos',
                path: '/centros_custos',
                alias: '/centros_custos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarCentroDeCusto',
                path: '/centros_custos/adicionar',
                component: () => import('@/domains/shared/financeiro/auxiliares/centros_custos/views/Adicionar')
            },
            {
                name: 'editarCentroDeCusto',
                path: '/centros_custos/editar/:id',
                component: () => import('@/domains/shared/financeiro/auxiliares/centros_custos/views/Editar')
            },
            {
                name: 'visualizarCentroDeCusto',
                path: '/centros_custos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
