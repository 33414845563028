const routes = [
    {
        path: '/contas_bancarias/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarContasBancarias',
                path: '/contas_bancarias',
                alias: '/contas_bancarias/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarContaBancaria',
                path: '/contas_bancarias/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarContaBancaria',
                path: '/contas_bancarias/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarContaBancaria',
                path: '/contas_bancarias/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
