const Interno = () => import('@/shared/components/layout/Interno')
const ListarTransportadoras = () => import('@/domains/shared/cadastros/transportadoras/views/Listar')
const AdicionarTransportadora = () => import('@/domains/shared/cadastros/transportadoras/views/Adicionar')
const EditarTransportadora = () => import('@/domains/shared/cadastros/transportadoras/views/Editar')
const VisualizarTransportadora = () => import('@/domains/shared/cadastros/transportadoras/views/Visualizar')

const routes = [
    {
        path: '/transportadoras/:id?',
        component: Interno,
        children: [
            {
                name: 'listarTransportadoras',
                path: '/transportadoras',
                alias: '/transportadoras/index',
                component: ListarTransportadoras,
            },
            {
                name: 'adicionarTransportadoras',
                path: '/transportadoras/adicionar',
                component: AdicionarTransportadora,
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarTransportadoras',
                path: '/transportadoras/editar/:id',
                component: EditarTransportadora,
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarTransportadoras',
                path: '/transportadoras/visualizar/:id',
                component: VisualizarTransportadora,
            }
        ]
    },
]

export default routes
