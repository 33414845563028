const routes = [
    {
        path: '/atributos_atendimentos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAtributosAtendimentos',
                path: '/atributos_atendimentos',
                alias: '/atributos_atendimentos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarAtributoAtendimento',
                path: '/atributos_atendimentos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarAtributoAtendimento',
                path: '/atributos_atendimentos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarAtributoAtendimento',
                path: '/atributos_atendimentos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
