const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarNcmTributacoes',
                path: '/ncm',
                alias: '/ncm/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarNcmTributacao',
                path: '/ncm/adicionar',
                component: () => import('../views/Adicionar'),
            },
            {
                name: 'visualizarNcmTributacao',
                path: '/ncm/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            {
                name: 'editarNcmTributacao',
                path: '/ncm/editar/:id',
                component: () => import('../views/Editar'),
            },
        ]
    },
]
export default routes