import { Validator } from 'vee-validate'
import { validaCpf, validaCnpj } from '@/shared/common/functions/numero'

Validator.localize('pt');

Validator.extend('cpf', {
    getMessage: 'Digite um CPF válido',
    validate: ((cpf) => {
        return validaCpf(cpf)
    })
});

Validator.extend('cnpj', {
    getMessage: 'Digite um CNPJ válido',
    validate: ((cnpj) => {
        return validaCnpj(cnpj)
    })
});

Validator.extend('cpf-cnpj', {
    getMessage: 'Digite um CPF/CNPJ válido',
    validate: ((value) => {
        const cpfCnpj = value.replace(/[^\d]/g, "")
        if (cpfCnpj.length > 11) {
            return validaCnpj(value)
        } else {
            return validaCpf(value)
        }
    })
});
