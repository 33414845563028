const routes = [
    {
        path: '/atividades_servicos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAtividadesServicos',
                path: '/atividades_servicos',
                alias: '/atividades_servicos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarAtividadeServico',
                path: '/atividades_servicos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarAtividadeServico',
                path: '/atividades_servicos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarAtividadeServico',
                path: '/atividades_servicos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
