const Interno = () => import('@/shared/components/layout/Interno')
const GerenciarPermissaoUsuario = () => import('@/domains/shared/configuracoes/permissoes/views/GerenciarUsuario')
const GerenciarPermissaoGrupo = () => import('@/domains/shared/configuracoes/permissoes/views/GerenciarGrupo')

const routes = [
    {
        path: '/permissoes/:id?',
        component: Interno,
        children: [
            {
                path: '/permissoes/gerenciar_usuario/:id',
                component: GerenciarPermissaoUsuario,
            },
            {
                path: '/permissoes/gerenciar_grupo/:id',
                component: GerenciarPermissaoGrupo,
            }
        ]
    },
]

export default routes