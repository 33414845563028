export const mostraMenu = () => {
    const rowOffcanvas = document.querySelector('.row-offcanvas')
    if(rowOffcanvas){
        rowOffcanvas.classList.add('relative')
        rowOffcanvas.classList.add('active')
    }
    const leftSide = document.querySelector('.left-side')
    if(leftSide){
        leftSide.classList.remove('collapse-left')
    }
    const rightSide = document.querySelector('.right-side')
    if(rightSide){
        rightSide.classList.remove('strech')
    }
    const botaoOperacoes = document.querySelector('.botao-operacoes')
    if(botaoOperacoes){
        botaoOperacoes.classList.add('d-none')
    }
}

export const escondeMenu = () =>{
    const rowOffcanvas = document.querySelector('.row-offcanvas')
    if(rowOffcanvas){
        rowOffcanvas.classList.remove('relative')
        rowOffcanvas.classList.remove('active')
    }
    const leftSide = document.querySelector('.left-side')
    if(leftSide){
        leftSide.classList.add('collapse-left')
    }
    const rightSide = document.querySelector('.right-side')
    if(rightSide){
        rightSide.classList.add('strech')
    }
    const botaoOperacoes = document.querySelector('.botao-operacoes')
    if(botaoOperacoes){
        botaoOperacoes.classList.remove('d-none')
    }
}