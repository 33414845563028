import Vue from 'vue'
import { somenteNumeros } from './global'
Vue.directive('mascara-horario', {
    inserted: function (el) {
        const limite = 5
        el.maxLength = limite
        el.addEventListener('keypress', function( e ) {
            return somenteNumeros(e) 
        }, true)
        el.addEventListener('focus', function() {
            el.setAttribute('placeholder', '__:__')
        }, true)
        el.addEventListener('keyup', function(e) {
            let tecla = e.keyCode;
            let vr = e.target.value;
            vr = vr.replace(/[-.]/g, '')
            let tam = vr.length + 1
            if (tecla != 9 && tecla != 8)
            {
                if (tam == 3){
                    e.target.value = vr.substr(0,3) + ':' + vr.substr(3, tam)
                }
            }
            if(e.target.value.length >= limite){
                let txt = e.target.value.substring(0, limite)
                e.target.value = txt
            }
        }, true)
    }
})