const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarRelatoriosContratos',
                path: '/relatorios_contratos',
                alias: '/relatorios_contratos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'formularioRelatoriosAssinatura',
                path: '/relatorios_contratos/relatorio_servicos_recorrentes',
                component: () => import('../views/Assinatura')
            },
            {
                name: 'formularioRelatoriosLocacaoCliente',
                path: '/relatorios_contratos/relatorio_locacoes_cliente',
                component: () => import('../views/LocacaoCliente')
            },
            {
                name: 'formularioRelatoriosProdutosLocados',
                path: '/relatorios_contratos/relatorio_produtos_locados',
                component: () => import('../views/ProdutosLocados')
            },
            {
                name: 'formularioRelatoriosDevolucaoProduto',
                path: '/relatorios_contratos/relatorio_devolucao_produto',
                component: () => import('../views/DevolucaoProduto')
            },
            {
                name: 'formularioRelatoriosContratoServico',
                path: '/relatorios_contratos/relatorio_servicos',
                component: () => import('../views/ContratoServico')
            },
            {
                name: 'formularioRelatoriosComissaoContrato',
                path: '/relatorios_contratos/relatorio_comissao_contrato',
                component: () => import('../views/ComissaoContrato')
            },
        ]
    },
    {
        name: 'impressaoRelatorio',
        path: '/relatorios_contratos/:tipo/gerar',
        component: () => import('@/shared/components/ui/Relatorio')
    },
]

export default routes
