const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'editarDadosEmpresa',
                path: '/configuracoes/dados_empresa',
                component: () => import('../views/DadosEmpresa')
            },
            {
                name: 'listarLojas',
                path: '/lojas',
                alias: '/lojas/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarLoja',
                path: '/lojas/adicionar',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarLoja',
                path: '/lojas/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarLoja',
                path: '/lojas/visualizar/:id',
                component: () => import('../views/Visualizar'),
            }
        ]
    },
]

export default routes
