import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BFormInputDate from './components/BFormInputDate'
import BFormInputDateBetween from './components/BFormInputDateBetween'
import BFormInputTime from './components/BFormInputTime'
import BFormInputPassword from './components/BFormInputPassword'
Vue.use(
    BootstrapVue, {
        formTextControls:{
            autocomplete: 'off'
        },
        BTooltip: {
            boundary: 'window',
            noninteractive: true
        }
    }
)
Vue.component('BFormInputPassword', BFormInputPassword)
Vue.component('BFormInputDate', BFormInputDate)
Vue.component('BFormInputDateBetween', BFormInputDateBetween)
Vue.component('BFormInputTime', BFormInputTime)
