import './bootstrap-vue'
import './fontawesome'
import './loading'
import './vee-validate'
//import './recaptcha'
import './vue-select'
import './vue-tour'
import './vue-mobile-detection'
import './vue-quill-editor'
import './vue-codemirror'
import './vue-html2canvas'
