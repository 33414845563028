const routes = [
    {
        path: '/',
        component: () => import('@/shared/layouts/adminlte/components/Barra'),
        children: [ 
            {
                name: 'termos',
                path: '/termos',
                component: () => import('../views/Termos'),
                meta: {
                    fullScreen: true,
                    public: true
                }
            },
        ]
    }
]
export default routes
