const Visualizar = () => import('../views/Visualizar')
const Listar = () => import('../views/Listar')
const Editar = () => import('../views/Editar')
const Interno = () => import('@/shared/components/layout/Interno')

const routes = [
    {
        path: '/modelos_emails/:id?',
        component: Interno,
        children: [
            {
                name: 'listarModelosEmails',
                path: '/modelos_emails',
                alias: '/modelos_emails/index',
                component: Listar
            },
            {
                name: 'visualizarModeloEmail',
                path: '/modelos_emails/visualizar/:id',
                component: Visualizar
            },
            {
                name: 'editarModeloEmail',
                path: '/modelos_emails/editar/:id',
                component: Editar
            }
        ]
    }
]

export default routes
