const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarRelatoriosAgendamentos',
                path: '/relatorios_agendamentos',
                alias: '/relatorios_agendamentos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'formularioRelatoriosAgendamento',
                path: '/relatorios_agendamentos/relatorio_agendamentos',
                component: () => import('../views/Agendamento')
            },
        ]
    },
    {
        name: 'impressaoRelatorio',
        path: '/relatorios_agendamentos/:tipo/gerar',
        component: () => import('@/shared/components/ui/Relatorio')
    },
]

export default routes
