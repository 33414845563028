const routes = [
    {
        path: '/atributos_ordens_servicos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarAtributosOrdensServicos',
                path: '/atributos_ordens_servicos',
                alias: '/atributos_ordens_servicos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarAtributoOrdemServico',
                path: '/atributos_ordens_servicos/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarAtributoOrdemServico',
                path: '/atributos_ordens_servicos/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarAtributoOrdemServico',
                path: '/atributos_ordens_servicos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
