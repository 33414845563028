import Vue from 'vue'

export const buscaNovidades = async () => {
    const agora = Vue.prototype.$moment();
    const ultimaAtualizacaoHora = localStorage.getItem('atualizacao_novidade');
    if (ultimaAtualizacaoHora) {
        const horaAtualFormatada = agora.format('HH:mm');
        const [horaAtual, minutoAtual] = horaAtualFormatada.split(':').map(Number);
        const [ultimaHora, ultimoMinuto] = ultimaAtualizacaoHora.split(':').map(Number);
        const minutosAtual = horaAtual * 60 + minutoAtual;
        const minutosUltimaAtualizacao = ultimaHora * 60 + ultimoMinuto;
        if (minutosAtual - minutosUltimaAtualizacao < 60 && minutosAtual >= minutosUltimaAtualizacao) {
            return;
        }
    }
    localStorage.setItem('atualizacao_novidade', agora.format('HH:mm'));
    try {
        const res = await Vue.prototype.$api.cGet(Vue.prototype.$urlBase + '/novidades')
        if (res.code === 200 && res.status === 'success') {
            if (!res.data) {
                localStorage.removeItem('novidades');
            } else {
                localStorage.setItem('novidades', JSON.stringify(res.data))
            }
        } else {
            localStorage.removeItem('novidades')
        }
    } catch (erro) {
        localStorage.removeItem('novidades')
    }
}

export const buscaSelectores = (path) => {
    const novidadesStr = localStorage.getItem('novidades');
    if (!novidadesStr) {
        return [];
    }
    const correctedNovidadesStr = novidadesStr.replace(/´/g, "'");
    const novidades = JSON.parse(correctedNovidadesStr);
    const pathItems = novidades[path] || [];
    const todosItems = novidades['todos'] || [];
    const combinedItems = pathItems.concat(todosItems);
    const uniqueItems = combinedItems.filter((item, index, self) =>
        index === self.findIndex((t) => (
            (t.seletor === item.seletor && t.conteudo === item.conteudo )
        ))
    );
    return uniqueItems;
}

export const insereTagElementoNovidade = (selectores) => {
    selectores.forEach(({ seletor, conteudo, tooltip }) => {
        const elementos = document.querySelectorAll(seletor);
        elementos.forEach(elemento => {
            let conteudoTooltip = "";
            if (conteudo) {
                if (elemento.textContent.trim().includes(conteudo)) {
                    conteudoTooltip = conteudo;
                }
            } else {
                conteudoTooltip = elemento.textContent.trim();
            }
            if (conteudoTooltip) {
                const tagExistente = elemento.querySelector('.tag-novo');
                if (!tagExistente) {
                    const conteinerTooltip = document.createElement('span');
                    conteinerTooltip.classList.add('tag-novo');
                    if (tooltip) {
                        conteinerTooltip.classList.add('tooltips');
                        const textoTooltip = document.createElement('span');
                        textoTooltip.classList.add('tooltiptext');
                        textoTooltip.innerHTML = tooltip;
                        conteinerTooltip.appendChild(textoTooltip);
                    }
                    elemento.appendChild(conteinerTooltip);
                }
            }
        });
    });
};

export const insereNovidades = (path) => {
    try{
        setTimeout(() => {
            if (path.endsWith('/')) {
                path = path.slice(0, -1);
            }
            const selectores = buscaSelectores(path);
            insereTagElementoNovidade(selectores);
        }, 2000 );
    } catch (erro) {
        console.log(erro)
    }
}


