const routes = [
    {
        path: '/inicio',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'visualizarDashboard',
                path: '',
                alias: ['/inicio', '/dashboard/index'],
                component: () => import('../views/Iniciar')
            },
        ]
    }
]

export default routes
